const DEVELOPER_PORTAL_TRACKING_EVENTS = {
  developerPortal: {
    navBarMarketPlace: {
      clicked: true
    },
    navBarApiReferenceButton: {
      clicked: true
    },
    navBarCommunityButton: {
      clicked: true
    },
    navBarGuidesButton: {
      clicked: true
    },
    navBarSignUpButton: {
      clicked: true
    },
    navBarLoginButton: {
      clicked: true
    },
    navBarDashboardButton: {
      clicked: true
    },
    navBarLogoutButton: {
      clicked: true
    },
    landingPageStartBuildingButton: {
      clicked: true
    },
    landingPageExploreDocsButton: {
      clicked: true
    },
    applicationsPageCreateAppButton: {
      modalOpened: true,
      clicked: true
    },
    applicationsPageDeleteAppButton: {
      modalOpened: true,
      clicked: true
    },
    testAccountPageDeleteAccountButton: {
      modalOpened: true,
      clicked: true
    },
    applicationsPageOpenAppButton: {
      clicked: true
    },
    dashboardPageCreateTestAccountButton: {
      modalOpened: true,
      clicked: true
    },
    dashboardPageDeleteTestAccountButton: {
      modalOpened: true,
      clicked: true
    },
    dashboardPageOpenTestAccountButton: {
      clicked: true
    },
    oAuthPageCreateTestAccountButton: {
      modalOpened: true,
      clicked: true
    },
    oAuthPageSaveButton: {
      clicked: true
    },
    oAuthPageSelectScopesSelect: {
      changed: true
    },
    oAuthPageRegenerateSecretButton: {
      clicked: true
    },
    webhooksPageOpenWebhookButton: {
      clicked: true
    },
    webhooksPageChangeStatusButton: {
      changed: true
    },
    webhooksPageSendTestEventButton: {
      clicked: true
    },
    webhooksPageDeleteWebhookButton: {
      clicked: true
    },
    webhooksPageSaveWebhookButton: {
      clicked: true
    },
    webhooksPageStartCreatingButton: {
      clicked: true
    }
  }
};

export default DEVELOPER_PORTAL_TRACKING_EVENTS;
