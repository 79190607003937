import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  COLORS
} from '@podiumhq/design-system';
import { useTracking } from '@podiumhq/tracking';

import { useCreateApplicationMutation } from '../../graphql';
import { isEmptyString } from '../../lib/string-utils';

const Description = styled.p`
  color: ${COLORS.grey.light};
  font-size: small;
  padding-top: 10px;
`;

export default function CreateApplicationModal({ isOpen, setIsClosed }) {
  const history = useHistory();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [invalidName, setInvalidName] = useState(false);
  const createApplication = useCreateApplicationMutation();
  const { trackEvent } = useTracking();

  const onCreateAppClick = () => {
    setNameError('');
    setInvalidName(false);
    createApplication({ name: name.trim() })
      .then(({ data: { createDeveloperApplication: application } }) => {
        trackEvent('developerPortal.applicationsPageCreateAppButton.clicked', {
          applicationName: name.trim(),
          applicationUid: application.uid
        });
        history.push(`/application/${application.uid}`);
      })
      .catch((e) => {
        e.graphQLErrors.forEach((error) => {
          if (error.key === 'name' && error.message === 'has already been taken') {
            setInvalidName(true);
            setNameError('Application name has already been taken.');
          }
          if (error.key === 'name' && error.message === 'should be at most 255 character(s)') {
            setInvalidName(true);
            setNameError('Application name is too long.');
          }
        });
      });
  };

  return (
    <Modal contentLabel="Create Application Modal" onClose={setIsClosed} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <form>
          <ModalBody>
            <FormControl isInvalid={invalidName}>
              <Input
                placeholder="App Title Here..."
                onChange={(event) => {
                  setNameError('');
                  setName(event.target.value);
                }}
              />
              <FormErrorMessage>{nameError}</FormErrorMessage>
            </FormControl>
            <Description>
              Each application provides a unique set of configuration settings and authentication
              needed for making requests to Podiums API's.
            </Description>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm" spacing={2}>
              <Button
                data-test-id="modal-create-app-button"
                disabled={isEmptyString(name)}
                variant="primary"
                onClick={onCreateAppClick}
              >
                Create App
              </Button>
              <Button type="text" variant="secondary" onClick={setIsClosed}>
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

CreateApplicationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsClosed: PropTypes.func.isRequired
};
