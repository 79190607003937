import React from 'react';
import PropTypes from 'prop-types';
import {
  useApplicationListQuery,
  useTestAccountListQuery,
  useDeveloperAccountQuery
} from '../../graphql';

import DashboardPure from './DashboardPure';

export default function Dashboard({ authLoading }) {
  const { loading, applicationList, refetchApplications } = useApplicationListQuery({
    skip: authLoading
  });
  const { testAccountList, refetchTestAccounts } = useTestAccountListQuery();
  const { developerAccount } = useDeveloperAccountQuery();

  return (
    <DashboardPure
      applicationList={applicationList}
      refetchApplications={refetchApplications}
      loading={authLoading || loading}
      testAccountList={testAccountList}
      refetchTestAccounts={refetchTestAccounts}
      developerAccount={developerAccount}
    />
  );
}

Dashboard.propTypes = {
  authLoading: PropTypes.bool
};

Dashboard.defaultProps = {
  authLoading: false
};
