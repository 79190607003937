import { gql, useQuery } from '@apollo/client';

export const QUERY = gql`
  query testAccountList {
    testOrganizationList {
      uid
      name
      locationCount
      reviewRocketOrgUid
      aliasedEmail
    }
  }
`;

export const useTestAccountListQuery = () => {
  const { data, loading, refetch } = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const testAccountList = data?.testOrganizationList || [];

  return { testAccountList, loading, refetchTestAccounts: refetch };
};
