import { datadogRum } from '@datadog/browser-rum';
import { DD_APPLICATION_ID, DD_CLIENT_TOKEN } from '../../lib/env';

export default function initDatadog() {
  if (process.env.NODE_ENV === 'production') {
    datadogRum.init({
      applicationId: DD_APPLICATION_ID,
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'developer-portal',
      env: process.env.NODE_ENV,
      defaultPrivacyLevel: 'mask-user-input',
      trackFrustrations: true,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true
    });
  }
}
