import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import {
  COLORS,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Skeleton,
  Text,
  Box,
  useToast
} from '@podiumhq/design-system';
import { useTracking } from '@podiumhq/tracking';

import AreYouSureModal from '../../components/AreYouSureModal';
import { useSoftDeleteDeveloperApplicationMutation } from '../../graphql';

export default function ApplicationListTable({ applicationList, loading, refetchApplications }) {
  const history = useHistory();
  const sortedApplicationList = sortBy(applicationList, ['name']);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [modalUid, setModalUid] = useState();
  const { trackEvent } = useTracking();
  const softDeleteApplication = useSoftDeleteDeveloperApplicationMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const modalHeading = 'Are you sure you want to delete this App?';
  const modalSubheading = (
    <>
      Deleting this App will remove it from your Apps list, revoke any user authentications and stop
      all webhooks related to it.
      <br /> <br /> <b>Caution:</b> This action cannot be undone.
    </>
  );
  const onConfirmModal = () => {
    setIsDeleting(true);
    softDeleteApplication({ uid: modalUid })
      .then(() => {
        setIsDeleting(false);
        trackEvent('developerPortal.applicationsPageDeleteAppButton.clicked', {
          applicationUid: modalUid
        });
        onClose();

        if (refetchApplications != null) refetchApplications();

        toast({
          status: 'success',
          title: 'Successfully deleted application!'
        });
      })
      .catch(() => {
        setIsDeleting(false);
        toast({
          status: 'error',
          title: 'Error deleting application.'
        });
      });
  };

  const onCloseModal = () => {
    setModalUid(null);
    onClose();
  };
  const buildRow = (key, application, onClick, onOpenModal) => (
    <Row key={key} onClick={onClick}>
      <td>
        {loading ? (
          <Skeleton width="148px" height="12px" />
        ) : (
          <Text fontSize="lg" color={COLORS.blue.base}>
            {application.name}
          </Text>
        )}
      </td>
      <td>
        <Box display="flex">
          {loading ? (
            <Skeleton width="148px" height="12px" />
          ) : (
            <>
              <Text fontSize="lg" paddingRight="10%">
                {application.uid}
              </Text>
              <Text
                color={COLORS.blue.base}
                fontSize="lg"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(application.uid);
                }}
              >
                Copy ID
              </Text>
            </>
          )}
        </Box>
      </td>
      <td>
        <Menu eventListeners={false} isLazy>
          <MenuButton
            data-testid="app-menu-button"
            hideChevron
            variant="ghost"
            onClick={(e) => e.stopPropagation()}
          >
            <Icon type="MoreVertical" />
          </MenuButton>
          <MenuList enableAnimations>
            <MenuItem
              onClick={(e) => {
                trackEvent('developerPortal.applicationsPageDeleteAppButton.modalOpened', {
                  applicationUid: key
                });
                e.stopPropagation();
                setModalUid(key);
                onOpenModal();
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </td>
    </Row>
  );

  return (
    <>
      <AreYouSureModal
        isOpen={isOpen}
        onNo={onCloseModal}
        heading={modalHeading}
        onYes={onConfirmModal}
        yesButtonText="Delete"
        subheading={modalSubheading}
        noButtonText="Cancel"
        closeOnOverlayClick={false}
        yesButtonDisabled={isDeleting}
        yesButtonLoading={isDeleting}
        yesButtonLoadingText="Deleting..."
      />
      <Table>
        <thead>
          <HeaderRow>
            <th>App Name</th>
            <th>Uid</th>
          </HeaderRow>
        </thead>
        <tbody>
          {loading
            ? Array.from(Array(4).keys()).map((i) =>
                buildRow(
                  `application-list-ghost-row-${i}`,
                  <Skeleton width="148px" height="12px" />,
                  () => {},
                  () => {}
                )
              )
            : sortedApplicationList.map((application) =>
                buildRow(
                  application.uid,
                  application,
                  () => {
                    trackEvent('developerPortal.applicationsPageOpenAppButton.clicked', {
                      applicationUid: application.uid
                    });
                    history.push(`application/${application.uid}`);
                  },
                  onOpen
                )
              )}
        </tbody>
      </Table>
    </>
  );
}

ApplicationListTable.propTypes = {
  applicationList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired
    })
  ),
  loading: PropTypes.bool,
  refetchApplications: PropTypes.func
};

ApplicationListTable.defaultProps = {
  applicationList: [],
  loading: false
};

const Table = styled.table`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border: thin solid ${COLORS.silver.light};
`;

const HeaderRow = styled.tr`
  height: 75px;
  display: flex;
  align-items: center;
  th:first-of-type {
    flex: 0 0 30%;
    padding-left: 2%;
    text-align: left;
    font-weight: normal;
  }
  th {
    flex: 0 0 21%;
    padding-left: 2%;
    text-align: left;
    font-weight: normal;
  }
`;

const Row = styled.tr`
  display: flex;
  align-items: center;
  height: 75px;
  font-size: 1.05em;
  border-top: thin solid ${COLORS.silver.light};
  td:first-of-type {
    flex: 0 0 30%;
    width: 1px;
    text-align: left;
    padding-left: 2%;
  }
  td {
    flex: 0 0 31%;
    text-align: left;
    padding-left: 2%;
  }
  td:last-child {
    flex: 0 0 0;
    text-align: left;
    padding-left: 33%;
  }
  &:hover {
    background-color: ${COLORS.blue.base};
    cursor: pointer;
    td {
      color: white;
    }
    p {
      color: white;
    }
    svg {
      color: white;
    }
  }
`;
