import React from 'react';
import PropTypes from 'prop-types';

import {
  useDeleteWebhookMutation,
  useSendTestWebhookMutation,
  useUpdateApplicationWebhookMutation
} from '../../../graphql';
import ConfigWebhookPure from './ConfigWebhookPure';

export default function ConfigWebhook(props) {
  const { deleteWebhook } = useDeleteWebhookMutation();
  const updateApplicationWebhook = useUpdateApplicationWebhookMutation();
  const sendTestWebhook = useSendTestWebhookMutation();
  return (
    <ConfigWebhookPure
      {...props}
      deleteWebhook={deleteWebhook}
      onSubmit={updateApplicationWebhook}
      sendTestWebhook={sendTestWebhook}
    />
  );
}

ConfigWebhook.propTypes = {
  closeWebhook: PropTypes.func,
  uid: PropTypes.string.isRequired,
  webhook: PropTypes.shape({
    enabledEventTypes: PropTypes.arrayOf(PropTypes.string),
    locationUid: PropTypes.string,
    secret: PropTypes.string,
    url: PropTypes.string,
    uid: PropTypes.string
  }).isRequired
};
