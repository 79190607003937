import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { COLORS } from '@podiumhq/design-system';
import { IconBack } from '@podiumhq/icons';
import { useTracking } from '@podiumhq/tracking';

export default function WebhookRow({ selectWebhook, webhook }) {
  const { disabledReason, locationUid, organizationUid, url } = webhook;
  const { trackEvent } = useTracking();

  return (
    <Row
      data-testid="webhook-row"
      onClick={() => {
        trackEvent('developerPortal.webhooksPageOpenWebhookButton.clicked', {
          webhookUid: webhook.uid
        });
        selectWebhook(webhook);
      }}
    >
      <Tmp>
        <Url>{url}</Url>
        <Entity>{locationUid ? `Loc: ${locationUid}` : `Org: ${organizationUid}`}</Entity>
      </Tmp>
      <Tmp1>{disabledReason ? <Disabled>Disabled</Disabled> : <Enabled>Enabled</Enabled>}</Tmp1>
      <Tmp2>
        <IconBack rotate={180} size={25} color={COLORS.grey.light} />
      </Tmp2>
    </Row>
  );
}

WebhookRow.propTypes = {
  selectWebhook: PropTypes.func.isRequired,
  webhook: PropTypes.shape({
    disabledReason: PropTypes.string,
    enabledEventTypes: PropTypes.arrayOf(PropTypes.string),
    locationUid: PropTypes.string,
    organizationUid: PropTypes.string,
    secret: PropTypes.string,
    url: PropTypes.string,
    uid: PropTypes.string
  }).isRequired
};

const Row = styled.tr`
  align-items: center;
  border-bottom: thin solid ${COLORS.silver.base};
  display: flex;
  font-size: 1.05em;
  height: 75px;
  justify-content: space-between;
  padding: 5px 10px;
  td {
    text-align: left;
  }
  &:hover {
    cursor: pointer;
    background-color: ${COLORS.blue.lighter};
    div {
      color: white;
    }
    svg {
      color: white;
    }
  }
`;

const Disabled = styled.span`
  color: white;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px 5px;
  background-color: ${COLORS.red.light};
`;

const Enabled = styled.span`
  color: white;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px 5px;
  background-color: ${COLORS.green.light};
`;

const Entity = styled.div`
  color: ${COLORS.grey.light};
`;

const Url = styled.div`
  color: ${COLORS.grey.light};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const Tmp = styled.td`
  width: 75%;
`;

const Tmp1 = styled.td`
  width: 15%;
`;

const Tmp2 = styled.td`
  width: 10%;
`;
