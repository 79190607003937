import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import addFullstory, { addUserToFullstory } from './fullstory';
import { addPendo, pendoScript } from './pendo/pendo';
import addPodiumWidget from './podiumWidget';
import amplitudeTracking from './amplitudeTracking';
import initDatadog from './initDatadog';

import { FULLSTORY_ORG_ID, PODIUM_WIDGET_API_TOKEN } from '../../lib/env';

let isAlreadyInDOM = false;
function ThirdPartyScripts() {
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => isAuthenticated && addPodiumWidget(PODIUM_WIDGET_API_TOKEN));

  useEffect(() => {
    addPendo(window.pendo, user);
    addUserToFullstory(user);
  }, [user]);

  if (isAlreadyInDOM) return null;
  isAlreadyInDOM = true;

  return [
    <iframe
      title="fullstory"
      key="fullstory"
      srcDoc={addFullstory(FULLSTORY_ORG_ID)}
      style={{ display: 'none' }}
    />,
    <iframe
      title="amplitude"
      key="amplitude"
      srcDoc={amplitudeTracking()}
      style={{ display: 'none' }}
    />,
    <iframe title="pendo" key="pendo" srcDoc={pendoScript()} style={{ display: 'none' }} />,
    <iframe title="datadog" key="datadog" srcDoc={initDatadog()} style={{ display: 'none' }} />
  ];
}

export default ThirdPartyScripts;
