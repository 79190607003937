export const isEmptyString = (value) => {
  if (!value) return true;
  return value.trim() === '';
};

export const trimFormValues = (formValues) =>
  Object.keys(formValues).reduce((acc, key) => {
    if (typeof formValues[key] === 'string') {
      acc[key] = formValues[key].trim();
    } else {
      acc[key] = formValues[key];
    }
    return acc;
  }, {});
