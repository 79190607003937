import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation createPresignedLogoUrl($developerApplicationUid: UUID!, $extension: String!) {
    createPresignedLogoUrl(
      developerApplicationUid: $developerApplicationUid
      extension: $extension
    ) {
      presignedUrl
      url
    }
  }
`;

export const useCreatePresignedLogoUrl = () => {
  const [mutate] = useMutation(MUTATION, {});

  return (variables) => mutate({ variables });
};
