import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import {
  FormHelperText,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@podiumhq/design-system';

import { isEmptyString, trimFormValues } from '../../../lib/string-utils';
import Config from '../Config';
import ResourceLinksTable from '../ResourceLinksTable';
import { PUBLIC_DOCUMENTATION_HOST } from '../../../lib/env';

const isSaveDisabled = (formValues, originalValues) =>
  isEqual(formValues, originalValues) || isEmptyString(formValues.name);

export default function ConfigBasicInfoPure({ onSubmit, name, uid }) {
  const [originalValues, setOriginalValues] = useState({ name, uid });
  const [formValues, setFormValues] = useState(originalValues);
  const [nameError, setNameError] = useState('');
  const [invalid, setInvalid] = useState(false);

  const onSave = () => {
    const cleanedFormValues = trimFormValues(formValues);
    setInvalid(false);
    return onSubmit(cleanedFormValues)
      .then(() => {
        setFormValues(cleanedFormValues);
        setOriginalValues(cleanedFormValues);
      })
      .catch((e) => {
        e.graphQLErrors.forEach((error) => {
          if (error.key === 'name' && error.message === 'has already been taken') {
            setNameError('Application name has already been taken.');
            setInvalid(true);
          }
          if (error.key === 'name' && error.message === 'should be at most 255 character(s)') {
            setNameError('Application name is too long.');
            setInvalid(true);
          }
        });
        return Promise.reject();
      });
  };

  const form = (
    <FormWrapper>
      <FormControl isInvalid={invalid}>
        <FormLabel htmlFor="basicInfoNameInput">Application Name</FormLabel>
        <Input
          data-testid="app-name"
          id="basicInfoNameInput"
          onChange={(e) => {
            setNameError('');
            setFormValues({ ...formValues, name: e.target.value });
          }}
          value={formValues.name}
        />
        <FormErrorMessage>{nameError}</FormErrorMessage>
        <FormHelperText>Keep it simple</FormHelperText>
      </FormControl>
    </FormWrapper>
  );

  const resourceLinks = [{ text: 'Overview', url: PUBLIC_DOCUMENTATION_HOST }];

  const resources = (
    <div>
      <ResourceText>
        Provide a name for your connecting client that will be displayed to the user in the Podium
        OAuth flow.
      </ResourceText>
      <ResourceLinksTable resources={resourceLinks} />
    </div>
  );

  return (
    <Config
      form={form}
      onSave={onSave}
      resources={resources}
      saveDisabled={isSaveDisabled(formValues, originalValues)}
      title="Basic Info"
    />
  );
}

ConfigBasicInfoPure.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired
};

const FormWrapper = styled.div`
  max-width: 500px;
`;

const ResourceText = styled.p`
  padding-bottom: 10px;
`;
