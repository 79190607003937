import React from 'react';
import PropTypes from 'prop-types';

import ConfigBasicInfoPure from './ConfigBasicInfoPure';
import { useUpdateApplicationMutation } from '../../../graphql';

export default function ConfigBasicInfo(props) {
  const updateApplication = useUpdateApplicationMutation();
  return <ConfigBasicInfoPure {...props} onSubmit={updateApplication} />;
}

ConfigBasicInfo.propTypes = {
  name: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired
};
