import { tracking } from '@podiumhq/tracking';
import { AMPLITUDE_TOKEN, AMPLITUDE_ENABLED } from '../../lib/env';
import DEVELOPER_PORTAL_TRACKING_EVENTS from './trackingEvents';
import isQABot from '../../lib/qa-bot-utils/isQABot';

const events = {
  ...DEVELOPER_PORTAL_TRACKING_EVENTS
};

function setupTracking({ enabled, debug }) {
  tracking.init({
    enabled,
    events,
    debug,
    amplitudeToken: AMPLITUDE_TOKEN,
    amplitudeConfig: {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
      batchEvents: true,
      saveEvents: true,
      eventUploadPeriodMillis: 10000,
      plan: {
        version: 1.0
      }
    }
  });
}

function amplitudeTracking() {
  const isAmplitudeEnabled = AMPLITUDE_ENABLED === 'true';
  const shouldTrack = isAmplitudeEnabled && !isQABot();

  setupTracking({ enabled: shouldTrack, debug: false });
  return null;
}

export default amplitudeTracking;
