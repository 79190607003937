window.env = window.env || {};

const getEnv = (key) => window.env[key] || process.env[key];

export const AMPLITUDE_ENABLED = getEnv('REACT_APP_AMPLITUDE_ENABLED');

export const AMPLITUDE_TOKEN = getEnv('REACT_APP_AMPLITUDE_TOKEN');

export const AUTH0_AUDIENCE =
  getEnv('REACT_APP_AUTH0_AUDIENCE') || 'https://developer-ecosystem.podium.com';

export const AUTH0_CLIENT_ID =
  getEnv('REACT_APP_AUTH0_CLIENT_ID') || '32Goyc7LbHh2tDuGarST6BvIq5Aal0YB';

export const AUTH0_DOMAIN = getEnv('REACT_APP_AUTH0_DOMAIN') || 'dev-podium.auth0.com';

export const CLICKWRAP_ACCESS_ID = getEnv('REACT_APP_CLICKWRAP_ACCESS_ID');

export const CLICKWRAP_GROUP_ID = getEnv('REACT_APP_CLICKWRAP_GROUP_ID');

export const CLICKWRAP_GROUP_KEY = getEnv('REACT_APP_CLICKWRAP_GROUP_KEY');

export const DD_APPLICATION_ID = getEnv('REACT_APP_DD_APPLICATION_ID');

export const DD_CLIENT_TOKEN = getEnv('REACT_APP_DD_CLIENT_TOKEN');

export const DEVELOPER_PORTAL_GATEWAY_HOST =
  getEnv('REACT_APP_DEVELOPER_PORTAL_GATEWAY_HOST') || 'http://localhost:5600';

export const FULLSTORY_ORG_ID = getEnv('REACT_APP_FULLSTORY_ORG_ID') || '';

export const PENDO_API_KEY = getEnv('REACT_APP_PENDO_API_KEY') || '';

export const MITHRANDIR_HOST = getEnv('REACT_APP_MITHRANDIR_HOST') || 'http://localhost:1892';

export const PODIUM_WIDGET_API_TOKEN = getEnv('REACT_APP_PODIUM_WIDGET_API_TOKEN') || '';

export const PUBLIC_DOCUMENTATION_HOST = 'https://docs.podium.com';
