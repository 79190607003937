import React from 'react';
import styled from '@emotion/styled';
import { COLORS } from '@podiumhq/design-system';
import PropTypes from 'prop-types';
import { useTracking } from '@podiumhq/tracking';

import Config from '../Config';
import ResourceLinksTable from '../ResourceLinksTable';
import WebhookRow from './WebhookRow';
import { PUBLIC_DOCUMENTATION_HOST } from '../../../lib/env';

export default function ConfigWebhooksPure(props) {
  const { webhooks } = props;
  const { trackEvent } = useTracking();

  const form = (
    <div>
      {webhooks && webhooks.length ? (
        <Table>
          <tbody>
            {webhooks.map((webhook) => (
              <WebhookRow key={webhook.uid} {...props} webhook={webhook} />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyState data-testid="empty-state">
          No webhooks created. Get started by creating webhooks subscriptions using the&nbsp;
          <a
            href={`${PUBLIC_DOCUMENTATION_HOST}/reference/webhookcreate-1`}
            onClick={() => trackEvent('developerPortal.webhooksPageStartCreatingButton.clicked')}
          >
            REST API
          </a>
          .
        </EmptyState>
      )}
    </div>
  );

  const resources = (
    <div>
      <ResourceText>
        Webhooks provide a way for you to be notified when something happens in a Podium account
        that you have been granted access for.
      </ResourceText>
      <ResourceLinksTable
        resources={[
          { text: 'Introduction to Webhooks', url: `${PUBLIC_DOCUMENTATION_HOST}/docs/webhooks` },
          {
            text: 'Verifying Webhook Signatures',
            url: `${PUBLIC_DOCUMENTATION_HOST}/docs/verifying-webhook-signatures`
          }
        ]}
      />
    </div>
  );

  return (
    <Config
      form={form}
      formStyle={{ padding: 0 }}
      hideControls
      onSave={() => {}}
      resources={resources}
      title="Webhooks"
    />
  );
}

ConfigWebhooksPure.propTypes = {
  uid: PropTypes.string.isRequired,
  webhooks: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const EmptyState = styled.div`
  padding: 25px;
`;

const ResourceText = styled.p`
  padding-bottom: 10px;
`;

const Table = styled.table`
  background-color: ${COLORS.white};
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
