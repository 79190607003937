import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { ThemeProvider } from '@podiumhq/design-system';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import AuthorizedApolloProvider from './lib/authorized-apollo-provider';
import Auth0ProviderWithHistory from './lib/auth0-provider-with-history';
import Root from './pages/Root';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import ThirdPartyScripts from './components/ThirdPartyScripts';

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <Auth0ProviderWithHistory>
        <AuthorizedApolloProvider>
          <ThirdPartyScripts />
          <ThemeProvider>
            <Root />
          </ThemeProvider>
        </AuthorizedApolloProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
);
