import { CLICKWRAP_ACCESS_ID, CLICKWRAP_GROUP_KEY, CLICKWRAP_GROUP_ID } from '../../lib/env';

export function getLatestContracts(userId) {
  const url = `https://pactsafe.io/latest?sid=${CLICKWRAP_ACCESS_ID}&gkey=${CLICKWRAP_GROUP_KEY}&sig=${userId}`;

  return fetch(url).then((response) => response.json());
}

export function sendAcceptance(userId, contractIds) {
  const body = {
    et: 'agreed',
    gid: CLICKWRAP_GROUP_ID,
    sid: CLICKWRAP_ACCESS_ID,
    sig: userId,
    vid: contractIds
  };

  return fetch('https://pactsafe.io/send', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
}
