import { gql, useMutation } from '@apollo/client';
import { allApplicationFields, updateApplicationCache } from './util';

export const MUTATION = gql`
  mutation updateDeveloperApplication($name: String!, $uid: UUID!) {
    updateDeveloperApplication(name: $name, uid: $uid) {
      ${allApplicationFields}
    }
  }
`;

export const useUpdateApplicationMutation = () => {
  const [mutate] = useMutation(MUTATION);
  return (variables) =>
    mutate({
      variables,
      update: (client, result) => {
        updateApplicationCache(client, result?.data?.updateDeveloperApplication);
      }
    });
};
