import React from 'react';
import propTypes from 'prop-types';
import { datadogRum } from '@datadog/browser-rum';

import Error from '../../pages/Error';
import UnexpectedError from '../../images/UnexpectedError.svg';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    datadogRum.addError(error);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <Error
          buttonOnClick={() => window.location.reload()}
          buttonText="Reload Page"
          header="Uh oh!"
          icon={UnexpectedError}
          iconAlt="Unexpected Error"
          message="Hmmm... looks like something went wrong on our end."
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: propTypes.node
};

ErrorBoundary.defaultProps = {
  children: null
};
