import React from 'react';
import styled from '@emotion/styled';
import { Button, COLORS } from '@podiumhq/design-system';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTracking } from '@podiumhq/tracking';

import logo from '../../images/DeveloperLogo.png';
import { PUBLIC_DOCUMENTATION_HOST } from '../../lib/env';
import TermsOfServiceModal from '../TermsOfServiceModal';

export default function TopNav() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useTracking();

  const renderLogButton = () => {
    if (isAuthenticated && location.pathname === '/') {
      return (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              history.push('/dashboard');
              trackEvent('developerPortal.navBarDashboardButton.clicked');
            }}
          >
            Dashboard
          </Button>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              logout();
              trackEvent('developerPortal.navBarLogoutButton.clicked');
            }}
          >
            Logout
          </Button>
        </>
      );
    }
    if (isAuthenticated) {
      return (
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            logout();
            trackEvent('developerPortal.navBarLogoutButton.clicked');
          }}
        >
          Logout
        </Button>
      );
    }
    return (
      <Button
        variant="primary"
        size="sm"
        onClick={() => {
          loginWithRedirect();
          trackEvent('developerPortal.navBarLoginButton.clicked');
        }}
      >
        Login
      </Button>
    );
  };

  return (
    <TopNavContainer>
      <FlexContainer>
        <TopNavHeader
          to={isAuthenticated && location.pathname !== '/' ? '/dashboard' : '/'}
          data-testid="TopNavHeader"
        >
          <img src={logo} height="28px" alt="Podium Logo" />
        </TopNavHeader>
      </FlexContainer>
      <TopNavItems>
        <li>
          <TopNavItem
            href="https://www.podium.com/marketplace/"
            target="_blank"
            rel="noreferrer"
            onClick={() => trackEvent('developerPortal.navBarMarketPlace.clicked')}
          >
            Marketplace
          </TopNavItem>
        </li>
        <li>
          <TopNavItem
            href={`${PUBLIC_DOCUMENTATION_HOST}/reference`}
            target="_blank"
            rel="noreferrer"
            onClick={() => trackEvent('developerPortal.navBarApiReferenceButton.clicked')}
          >
            API Reference
          </TopNavItem>
        </li>
        <li>
          <TopNavItem
            href={`${PUBLIC_DOCUMENTATION_HOST}/docs`}
            target="_blank"
            rel="noreferrer"
            onClick={() => trackEvent('developerPortal.navBarGuidesButton.clicked')}
          >
            Guides
          </TopNavItem>
        </li>
        {/* <li>
          <TopNavItem
            href="https://discord.gg/tB9uYk9p7E"
            target="_blank"
            rel="noreferrer"
            onClick={() => trackEvent('developerPortal.navBarCommunityButton.clicked')}
          >
            Community
          </TopNavItem>
        </li> */}
        {!isAuthenticated && (
          <li>
            <SignUpLink
              href="https://docs.google.com/forms/d/e/1FAIpQLSegNSRGdfu62ooYMpLpQJOWOYkfH1a47YT0tChuZ2gPbREUJA/viewform"
              rel="noreferrer"
              onClick={() => trackEvent('developerPortal.navBarSignUpButton.clicked')}
            >
              Sign Up
            </SignUpLink>
          </li>
        )}
        <li>{renderLogButton()}</li>
      </TopNavItems>
      {process.env.NODE_ENV !== 'development' && <TermsOfServiceModal userId={user?.email} />}
    </TopNavContainer>
  );
}

const TopNavContainer = styled.div`
  border-bottom: 1px ${COLORS.silver.base} solid;
  justify-content: space-between;
  background-color: white;
  color: ${COLORS.silver.base};
  min-height: 60px;
  max-height: 60px;
  line-height: 60px;
  display: flex;
  padding: 0 38px;
  position: relative;
`;

const TopNavHeader = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.5em;
  font-size: 20px;
  text-decoration: none;
  color: inherit;
`;

const TopNavItems = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  li {
    padding-left: 16px;
    padding-right: 16px;
  }
  button {
    vertical-align: baseline;
    margin-left: 8px;
  }
`;

const TopNavItem = styled.a`
  text-decoration: none;
  padding-top: 6px;
  padding-bottom: 8px;
  color: ${COLORS.black.base};
  &:hover {
    cursor: pointer;
    border-bottom: 3px solid ${COLORS.blue.base};
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

const SignUpLink = styled(TopNavItem)`
  color: ${COLORS.blue.base};
  &:hover {
    border: none;
    color: #2353cc;
  }
`;
