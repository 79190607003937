import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { allApplicationFields, updateApplicationCache } from './util';

export const MUTATION = gql`
  mutation upsertDeveloperApplicationOauthClient(
    $developerApplicationUid: UUID!
    $logoUrl: String
    $redirectUrl: String!
    $scopes: [String!]
  ) {
    upsertDeveloperApplicationOauthClient(
      developerApplicationUid: $developerApplicationUid,
      logoUrl: $logoUrl,
      redirectUrl: $redirectUrl,
      scopes: $scopes
    ) {
      ${allApplicationFields}
    }
  }
`;

export const useUpsertApplicationOauthClientMutation = () => {
  const [mutate, { data }] = useMutation(MUTATION);
  const clientSecret = data?.upsertDeveloperApplicationOauthClient?.oauthClient.clientSecret;

  const upsertDeveloperApplicationOauthClient = (variables) =>
    mutate({
      variables,
      update: (client, result) => {
        updateApplicationCache(client, result?.data?.upsertDeveloperApplicationOauthClient);
      }
    });

  return { upsertDeveloperApplicationOauthClient, clientSecret };
};
