import React, { useState } from 'react';
import { Box, Button, COLORS, Heading, Image, Link, Text } from '@podiumhq/design-system';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTracking } from '@podiumhq/tracking';
import ApplicationListTable from './ApplicationListTable';
import CreateApplicationModal from '../../components/CreateApplicationModal';
import CreateTestAccountModal from '../../components/CreateTestAccountModal';
import TestAccountListTable from './TestAccountListTable';
import rocketOne from '../../images/Rocket1.png';
import rocketTwo from '../../images/Rocket2.png';

export default function DashboardPure({
  applicationList,
  refetchApplications,
  testAccountList,
  refetchTestAccounts,
  loading,
  developerAccount
}) {
  const [isCreateApplicationModalOpen, setIsCreateApplicationModalOpen] = useState(false);
  const [isCreateTestAccountModalOpen, setIsCreateTestAccountModalOpen] = useState(false);
  const { trackEvent } = useTracking();

  const createAppOnClick = () => {
    setIsCreateApplicationModalOpen(true);
    trackEvent('developerPortal.applicationsPageCreateAppButton.modalOpened');
  };

  const createTestAccountOnClick = () => {
    setIsCreateTestAccountModalOpen(true);
    trackEvent('developerPortal.dashboardPageCreateTestAccountButton.modalOpened');
  };

  return (
    <Dashboard>
      <Box width="85%" marginBottom="100px">
        <HeaderBox>
          <Heading fontWeight="semibold" fontSize="2xl">
            Manage Apps
          </Heading>
          {(loading || applicationList.length > 0) && (
            <Button
              data-e2e="create-app-button"
              disabled={loading}
              onClick={createAppOnClick}
              variant="primary"
            >
              Create App
            </Button>
          )}
        </HeaderBox>
        {loading || (applicationList && applicationList.length) ? (
          <ApplicationListTable
            applicationList={applicationList}
            loading={loading}
            refetchApplications={refetchApplications}
          />
        ) : (
          <Empty>
            <Image margin="30px 30px 0 30px" src={rocketOne} boxSize="120px" />
            <BoxText>
              <Heading paddingBottom="1%" fontSize="xl" fontWeight="semibold">
                Let's build your first App
              </Heading>
              <Text paddingBottom="1%" fontSize="lg">
                Applications allow developers to extend Podium's functionality by connecting it to
                data from your product.
              </Text>
              <Text fontSize="sm" paddingRight="40%">
                Sync contact information like payments or purchase events and other metadata, send
                messages and request reviews based on triggers from your product, and more. Learn
                more about{' '}
                <Text
                  as="a"
                  href="https://docs.podium.com/docs#step-2--create-an-app-on-the-podium-developer-portal"
                  target="_blank"
                  rel="noreferrer"
                  color={COLORS.blue.base}
                  fontSize="sm"
                >
                  Applications.
                </Text>
              </Text>
              <StyledButton
                data-e2e="create-app-button"
                variant="ghost"
                rightIcon="ArrowRight"
                onClick={createAppOnClick}
              >
                Create App
              </StyledButton>
            </BoxText>
          </Empty>
        )}
      </Box>
      <CreateApplicationModal
        isOpen={isCreateApplicationModalOpen}
        setIsClosed={(e) => {
          e.preventDefault();
          setIsCreateApplicationModalOpen(false);
        }}
      />
      <Box width="85%">
        <HeaderBox>
          <Heading fontWeight="semibold" fontSize="2xl">
            Test Accounts
          </Heading>
          {(loading || testAccountList.length > 0) && (
            <Button
              data-e2e="create-test-account-button"
              variant="primary"
              disabled={loading}
              onClick={createTestAccountOnClick}
            >
              Create Test Account
            </Button>
          )}
        </HeaderBox>
        {loading || (testAccountList && testAccountList.length) ? (
          <TestAccountListTable
            testAccountList={testAccountList}
            loading={loading}
            refetchTestAccounts={refetchTestAccounts}
          />
        ) : (
          <Empty>
            <Image margin="30px 30px 0 30px" boxSize="120px" src={rocketTwo} />
            <BoxText>
              <Heading fontSize="xl" paddingBottom="1%" fontWeight="semibold">
                Test things out
              </Heading>
              <Text paddingBottom="1%" fontSize="lg">
                Test accounts allow you to kick the tires without affecting production data or
                incurring costs.
              </Text>
              <Text fontSize="sm" paddingRight="40%">
                Create a test account to explore our APIs and features, test your application, and
                preview functionality before publishing. Learn more about{' '}
                <Link
                  color={COLORS.blue.dark}
                  href="https://docs.podium.com/docs/podium-test-accounts"
                  isExternal
                >
                  Test Accounts
                </Link>
                .
              </Text>
              <StyledButton
                data-e2e="create-test-account-button"
                variant="ghost"
                rightIcon="ArrowRight"
                onClick={createTestAccountOnClick}
              >
                Create Test Account
              </StyledButton>
            </BoxText>
          </Empty>
        )}
        <CreateTestAccountModal
          isOpen={isCreateTestAccountModalOpen}
          refetchTestAccounts={refetchTestAccounts}
          setIsClosed={() => {
            setIsCreateTestAccountModalOpen(false);
          }}
          developerAccount={developerAccount}
        />
      </Box>
    </Dashboard>
  );
}

DashboardPure.propTypes = {
  applicationList: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, uid: PropTypes.string.isRequired })
  ),
  refetchApplications: PropTypes.func,
  loading: PropTypes.bool,
  testAccountList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired
    })
  ),
  refetchTestAccounts: PropTypes.func,
  developerAccount: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
};

DashboardPure.defaultProps = {
  testAccountList: [],
  applicationList: [],
  loading: false,
  developerAccount: { firstName: undefined, lastName: undefined }
};

const Dashboard = styled.div`
  background-color: ${COLORS.silver.lighter};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  min-height: 100vh;
`;

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 35px;
  padding-bottom: 30px;
`;

const Empty = styled.div`
  background-color: white;

  border-radius: 10px;
  display: flex;
`;

const BoxText = styled.div`
  padding-top: 30px;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
  margin: 2% 0 2% 0;
  svg {
    color: ${COLORS.blue.base};
  }
`;
