import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation softDeleteDeveloperApplication($uid: UUID!) {
    softDeleteDeveloperApplication(uid: $uid) {
      uid
    }
  }
`;

export const useSoftDeleteDeveloperApplicationMutation = () => {
  const [mutate] = useMutation(MUTATION);
  return (variables) => mutate({ variables });
};
