import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation createTestAccount($firstName: String!, $lastName: String!, $locationCount: String!) {
    createTestOrganization(
      firstName: $firstName
      lastName: $lastName
      locationCount: $locationCount
    ) {
      uid
    }
  }
`;

export const useCreateTestAccountMutation = () => {
  const [mutate] = useMutation(MUTATION, {});
  return (variables) => mutate({ variables });
};
