import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const QUERY = gql`
  query developerAccount {
    developerAccount {
      firstName
      lastName
    }
  }
`;

export const useDeveloperAccountQuery = () => {
  const { data } = useQuery(QUERY, { fetchPolicy: 'cache-first' });
  const developerAccount = data?.developerAccount;
  return { developerAccount };
};
