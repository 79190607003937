import { gql, useMutation } from '@apollo/client';
import { deleteWebhookInCache } from './util';

export const MUTATION = gql`
  mutation deleteWebhook($uid: UUID!, $developerApplicationUid: UUID!) {
    deleteWebhook(uid: $uid, developerApplicationUid: $developerApplicationUid) {
      uid
    }
  }
`;

export const useDeleteWebhookMutation = () => {
  const [mutate] = useMutation(MUTATION);

  return {
    deleteWebhook: (variables) =>
      mutate({
        variables,
        update: (client) => {
          deleteWebhookInCache(client, variables);
        }
      })
  };
};
