import { gql, useQuery } from '@apollo/client';

export const QUERY = gql`
  query apiScopeList {
    apiScopeList {
      name
      slug
    }
  }
`;

export const useApiScopeListQuery = () => {
  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const apiScopeList = data?.apiScopeList;

  return { apiScopeList, loading };
};
