import { gql, useQuery } from '@apollo/client';

export const QUERY = gql`
  query featureFlagsList {
    featureFlagsList
  }
`;

export const useFeatureFlagsListQuery = () => {
  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const featureFlagsList = data?.featureFlagsList;

  return { featureFlagsList, loading };
};
