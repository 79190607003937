import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useTracking } from '@podiumhq/tracking';

import { PUBLIC_DOCUMENTATION_HOST } from '../../lib/env';

export default function LandingPage() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const onClickStartBuilding = () => {
    trackEvent('developerPortal.landingPageStartBuildingButton.clicked');
    if (isAuthenticated) {
      history.push('/dashboard');
    } else {
      loginWithRedirect();
    }
  };

  return (
    <Container>
      <img alt="developers" src="https://developer.podium.com/dev-portal-splash.jpg" height="500" />
      <ColumnContainer>
        <Title>Build new tools for over 100,000 local businesses.</Title>
        <Content>
          Do you have an in-house system that you want to connect to Podium? Or maybe you have an
          idea for an app that could make Podium work even better. The public APIs available in our
          Developer Portal allow you to do just that.
        </Content>
        <ButtonWrapper>
          <HeroButton onClick={() => onClickStartBuilding()}>Start building</HeroButton>
          <HeroButton
            type="secondary"
            onClick={(e) => {
              trackEvent('developerPortal.landingPageExploreDocsButton.clicked');
              e.preventDefault();
              window.location.href = `${PUBLIC_DOCUMENTATION_HOST}/docs`;
            }}
          >
            Explore the docs
          </HeroButton>
        </ButtonWrapper>
      </ColumnContainer>
    </Container>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8rem;
  gap: 24px;
  max-width: 1100px;
  margin: auto;
`;

const Content = styled.p`
  text-align: left;
`;
const HeroButton = styled.button`
  width: 230px;
  height: 55px;
  background-color: #4374E8;
  color: white;
  font-weight: 600;
  font-size 16px;
  line-height: 19px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #000;
    color: white;
    border:none
  }
  ${(props) =>
    props.type === 'secondary' &&
    `color: #4374E8; background-color: white; border: 2px solid #4374E8;`}
`;

const Title = styled.h1`
  font-size: 48px;
`;
