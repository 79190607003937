import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import {
  Box,
  COLORS,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Skeleton,
  Text,
  useToast
} from '@podiumhq/design-system';
import { useTracking } from '@podiumhq/tracking';
import { useSendWelcomeEmailMutation, useSoftDeleteTestOrganizationMutation } from '../../graphql';
import AreYouSureModal from '../../components/AreYouSureModal';
import { MITHRANDIR_HOST } from '../../lib/env';

export default function TestAccountListTable({ testAccountList, loading, refetchTestAccounts }) {
  const sortedTestAccountList = sortBy(testAccountList, ['name']);
  const [isTestAccountOnClickModal, setIsTestAccountOnClickModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [modalUid, setModalUid] = useState();
  const softDeleteTestOrganization = useSoftDeleteTestOrganizationMutation();
  const sendWelcomeEmail = useSendWelcomeEmailMutation();
  const { trackEvent } = useTracking();
  const toast = useToast();
  const TestAccountOnClickModalHeading = 'To access your test account, do the following:';
  const TestAccountOnClickModalSubheading = (
    <>
      {' '}
      1. Log out of your Podium account by{' '}
      <a href={`${MITHRANDIR_HOST}/logout`} target="_blank" rel="noopener noreferrer">
        {' '}
        clicking here{' '}
      </a>{' '}
      <br />
      2. Log into your test account{' '}
      <a href={MITHRANDIR_HOST} target="_blank" rel="noopener noreferrer">
        {' '}
        here{' '}
      </a>
    </>
  );
  const DeletingTestAccountModalHeading = 'Are you sure you want to delete this Test Account?';
  const DeletingTestAccountModalSubheading = (
    <>
      {' '}
      Deleting this Test Account will remove it from your dashboard. You will no longer have access
      to this test account.
      <br /> <br /> <b>Caution:</b> This action cannot be undone.
    </>
  );

  const onCloseTestAccountOnClickModal = () => {
    setIsTestAccountOnClickModal(false);
  };
  const onCloseModal = () => {
    setModalUid(null);
    onClose();
  };
  const onConfirmModal = () => {
    setIsDeleting(true);
    softDeleteTestOrganization({ uid: modalUid })
      .then(() => {
        setIsDeleting(false);
        trackEvent('developerPortal.testAccountPageDeleteAccountButton.clicked', {
          testAccountUid: modalUid
        });
        onClose();

        if (refetchTestAccounts != null) refetchTestAccounts();

        toast({
          status: 'success',
          title: 'Successfully deleted test account!'
        });
      })
      .catch(() => {
        setIsDeleting(false);
        toast({
          status: 'error',
          title: 'Error deleting test account.'
        });
      });
  };
  const buildRow = (key, testAccount, onClick, onOpenModal) => (
    <Row key={key} onClick={onClick}>
      <td>
        {loading ? (
          <Skeleton width="148px" height="12px" />
        ) : (
          <Text fontSize="lg">{testAccount.name}</Text>
        )}
      </td>
      <td>
        {loading ? (
          <Skeleton width="148px" height="12px" />
        ) : (
          <Text fontSize="lg">{testAccount.aliasedEmail}</Text>
        )}
      </td>
      <td>
        <Box display="flex">
          {loading ? (
            <Skeleton width="148px" height="12px" />
          ) : (
            <>
              <Text paddingRight="1em" fontSize="lg">
                {testAccount.reviewRocketOrgUid}
              </Text>
              <Text
                color={COLORS.blue.base}
                fontSize="lg"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(testAccount.reviewRocketOrgUid);
                }}
              >
                Copy ID
              </Text>
            </>
          )}
        </Box>
      </td>
      <td>
        {loading ? (
          <Skeleton width="148px" height="12px" />
        ) : (
          <>
            <Text
              fontSize="lg"
              marginRight="60%"
              whiteSpace="nowrap"
            >{`${testAccount.locationCount} location(s)`}</Text>
            <Box textAlign="right">
              <Menu eventListeners={false}>
                <MenuButton
                  data-testid="testAccount-menu-button"
                  hideChevron
                  variant="ghost"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icon type="MoreVertical" />
                </MenuButton>
                <MenuList enableAnimations>
                  <MenuItem
                    onClick={(e) => {
                      trackEvent(
                        'developerPortal.dashboardPageDeleteTestAccountButton.modalOpened',
                        {
                          testAccountUid: testAccount.uid
                        }
                      );
                      e.stopPropagation();
                      setModalUid(testAccount.uid);
                      onOpenModal();
                    }}
                  >
                    Delete
                  </MenuItem>
                  <MenuItem
                    onClick={async (e) => {
                      e.stopPropagation();

                      await sendWelcomeEmail({ testOrganizationId: testAccount.reviewRocketOrgUid })
                        .then(() => {
                          toast({
                            status: 'success',
                            title: 'Email sent'
                          });
                        })
                        .catch(() => {
                          toast({
                            status: 'error',
                            title: 'Error sending email'
                          });
                        });
                    }}
                  >
                    Resend Welcome Email
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </>
        )}
      </td>
    </Row>
  );

  return (
    <>
      <AreYouSureModal
        isOpen={isOpen}
        onNo={onCloseModal}
        heading={DeletingTestAccountModalHeading}
        onYes={onConfirmModal}
        yesButtonText="Delete"
        subheading={DeletingTestAccountModalSubheading}
        noButtonText="Cancel"
        closeOnOverlayClick={false}
        yesButtonDisabled={isDeleting}
        yesButtonLoading={isDeleting}
        yesButtonLoadingText="Deleting..."
      />
      <AreYouSureModal
        isOpen={isTestAccountOnClickModal}
        onNo={onCloseTestAccountOnClickModal}
        heading={TestAccountOnClickModalHeading}
        yesButtonText={null}
        subheading={TestAccountOnClickModalSubheading}
        noButtonText="Cancel"
      />
      <Table>
        <thead>
          <HeaderRow>
            <th>Test Account Name</th>
            <th>Email</th>
            <th>UID</th>
            <th>Number of Locations</th>
          </HeaderRow>
        </thead>
        <tbody>
          {loading
            ? Array.from(Array(4).keys()).map((i) =>
                buildRow(
                  `application-list-ghost-row-${i}`,
                  {
                    uid: '',
                    email: '',
                    name: '',
                    locationCount: '',
                    reviewRocketOrgUid: ''
                  },
                  () => {},
                  () => {}
                )
              )
            : sortedTestAccountList.map((testAccount) =>
                buildRow(
                  testAccount.uid,
                  testAccount,
                  () => {
                    trackEvent('developerPortal.dashboardPageOpenTestAccountButton.clicked', {
                      testAccountUid: testAccount.uid
                    });
                    setIsTestAccountOnClickModal(true);
                  },
                  onOpen
                )
              )}
        </tbody>
      </Table>
    </>
  );
}

TestAccountListTable.propTypes = {
  testAccountList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      locationCount: PropTypes.number.isRequired,
      reviewRocketOrgUid: PropTypes.string.isRequired,
      aliasedEmail: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  refetchTestAccounts: PropTypes.func
};

TestAccountListTable.defaultProps = {
  testAccountList: [],
  loading: false
};

const Table = styled.table`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border: thin solid ${COLORS.silver.light};
`;

const HeaderRow = styled.tr`
  height: 75px;
  display: flex;
  align-items: center;
  th:first-of-type {
    flex: 0 0 17%;
    padding-left: 2%;
    text-align: left;
    font-weight: normal;
  }
  th {
    flex: 0 0 23%;
    padding-left: 2%;
    text-align: left;
    font-weight: normal;
  }
  th {
    flex: 0 0 31%;
    padding-left: 2%;
    text-align: left;
    font-weight: normal;
  }
  th:last-child {
    flex: 0 0 25%;
    padding-left: 2%;
    text-align: left;
    font-weight: normal;
  }
`;

const Row = styled.tr`
  display: flex;
  align-items: center;
  height: 75px;
  font-size: 1.05em;
  border-top: thin solid ${COLORS.silver.light};
  td:first-of-type {
    flex: 0 0 17%;
    width: 1px;
    text-align: left;
    padding-left: 2%;
    color: ${COLORS.blue.base};
  }
  td {
    flex: 0 0 23%;
    text-align: left;
    padding-left: 2%;
  }
  td {
    flex: 0 0 25%;
    text-align: left;
    padding-left: 2%;
  }
  td {
    flex: 0 0 31%;
    text-align: left;
    padding-left: 2%;
  }
  td:last-child {
    display: flex;
    flex: 0 0 0;
    text-align: left;
  }
  &:hover {
    background-color: ${COLORS.blue.base};
    cursor: pointer;
    td {
      color: white;
    }
    p {
      color: white;
    }
    svg {
      color: white;
    }
  }
`;
