import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@podiumhq/design-system';

export default function AreYouSureModal({
  heading,
  isOpen,
  noButtonText,
  noButtonDisabled,
  onNo,
  onYes,
  subheading,
  yesButtonText,
  yesButtonDisabled,
  yesButtonLoading,
  yesButtonLoadingText,
  closeOnOverlayClick
}) {
  return (
    <Modal
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
      onClose={onNo}
      noButtonDisabled={noButtonDisabled}
      yesButtonDisabled={yesButtonDisabled}
      yesButtonLoading={yesButtonLoading}
    >
      <ModalOverlay />
      <ModalContent>
        <div data-testid="AreYouSureModal" />
        <ModalHeader>{heading}</ModalHeader>
        <ModalBody>{subheading}</ModalBody>
        <ModalFooter>
          {yesButtonText ? (
            <ButtonGroup size="sm" spacing={2}>
              <Button
                variant="primary"
                onClick={onYes}
                disabled={yesButtonDisabled}
                isLoading={yesButtonLoading}
                loadingText={yesButtonLoadingText}
              >
                {yesButtonText}
              </Button>
              <Button type="text" variant="secondary" onClick={onNo} disabled={noButtonDisabled}>
                {noButtonText}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup size="sm" spacing={2}>
              <Button type="text" variant="secondary" onClick={onNo} disabled={noButtonDisabled}>
                {noButtonText}
              </Button>
            </ButtonGroup>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

AreYouSureModal.propTypes = {
  noButtonDisabled: PropTypes.bool,
  yesButtonDisabled: PropTypes.bool,
  yesButtonLoading: PropTypes.bool,
  yesButtonLoadingText: PropTypes.string,
  heading: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  noButtonText: PropTypes.string.isRequired,
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func,
  subheading: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
  yesButtonText: PropTypes.string,
  closeOnOverlayClick: PropTypes.bool
};

AreYouSureModal.defaultProps = {};
