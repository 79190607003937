import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { COLORS } from '@podiumhq/design-system';
import isEmpty from 'lodash/isEmpty';

import { useApplicationQuery } from '../../graphql';
import ConfigBasicInfo from './ConfigBasicInfo';
import ConfigOauth from './ConfigOauth';
import ConfigWebhooks from './ConfigWebhooks';
import Error from '../Error';
import NotFoundError from '../../images/NotFoundError.svg';

const LeftPanel = styled.div`
  background: #fff;
  padding: 20px 30px 0;
  min-width: 200px;
  width: 200px;
  border-right: 1px solid rgb(231, 236, 242);
  background-color: ${COLORS.silver.lighter};
  height: 100%;
`;

const LeftPanelDivider = styled.div`
  border-bottom: 1px solid ${COLORS.silver.base};
  margin: 16px 0;
`;

const LeftPanelItem = styled.button`
  height: 32px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: ${COLORS.grey.light};
  cursor: pointer;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  user-select: none;
  border-radius: 0px 4px 4px 0px;
  border-left: 3px solid ${COLORS.silver.base};

  &:hover {
    background-color: ${COLORS.silver.base};
  }

  ${({ selected }) => {
    if (selected) {
      return `
        border-left: 3px solid ${COLORS.blue.light};
        color: ${COLORS.blue.light};
        font-weight: 500;
      `;
    }
    return `
      &:focus
    `;
  }}
`;

const LeftPanelTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
`;

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 80px);
`;

const configType = {
  OAUTH: 1,
  BASIC_INFO: 2,
  WEBHOOKS: 3
};

export default function Application() {
  const { applicationUid } = useParams();
  const { application, loading } = useApplicationQuery(applicationUid);

  const [selectedConfig, setSelectedConfig] = useState(configType.BASIC_INFO);
  const [selectedWebhook, setSelectedWebhook] = useState(null);

  if (loading) {
    return <div />;
  }

  if (isEmpty(application)) {
    return (
      <Error
        header="Application not found"
        icon={NotFoundError}
        iconAlt="Not Found Error"
        message="The application you are trying to access does not exist."
      />
    );
  }

  return (
    <Wrapper>
      <LeftPanel>
        <LeftPanelTitle>{application.name}</LeftPanelTitle>
        <LeftPanelDivider />
        <LeftPanelItem
          onClick={() => setSelectedConfig(configType.BASIC_INFO)}
          selected={selectedConfig === configType.BASIC_INFO}
        >
          Basic Info
        </LeftPanelItem>
        <LeftPanelItem
          onClick={() => setSelectedConfig(configType.OAUTH)}
          selected={selectedConfig === configType.OAUTH}
        >
          OAuth
        </LeftPanelItem>
        <LeftPanelItem
          onClick={() => {
            setSelectedWebhook(null);
            setSelectedConfig(configType.WEBHOOKS);
          }}
          selected={selectedConfig === configType.WEBHOOKS}
        >
          Webhooks
        </LeftPanelItem>
      </LeftPanel>
      {selectedConfig === configType.BASIC_INFO && <ConfigBasicInfo {...application} />}
      {selectedConfig === configType.OAUTH && <ConfigOauth {...application} />}
      {selectedConfig === configType.WEBHOOKS && (
        <ConfigWebhooks
          selectedWebhook={selectedWebhook}
          selectWebhook={setSelectedWebhook}
          {...application}
        />
      )}
    </Wrapper>
  );
}
