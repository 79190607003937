import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  COLORS,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast
} from '@podiumhq/design-system';
import { useTracking } from '@podiumhq/tracking';
import { isEmptyString } from '../../lib/string-utils';
import { useCreateTestAccountMutation } from '../../graphql';

export default function CreateTestAccountModal({
  developerAccount,
  isOpen,
  onCreate,
  refetchTestAccounts,
  setIsClosed
}) {
  const [isNextStep, setIsNextStep] = useState(false);
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [locationCount, setLocationCount] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const createTestAccount = useCreateTestAccountMutation();
  const { trackEvent } = useTracking();
  const toast = useToast();

  let hasAccountName;
  if (developerAccount === undefined) {
    hasAccountName = false;
  } else {
    hasAccountName = developerAccount.firstName && developerAccount.lastName;
  }

  const cancelOnClick = () => {
    setIsNextStep(false);
    setName(null);
    setLocationCount(null);
    setIsClosed();
  };

  const onCreateTestAccountClick = async () => {
    setIsCreating(true);
    let firstName;
    let lastName;
    if (name !== null) {
      [firstName] = name.split(' ');
      lastName = name.split(' ').slice(1).join(' ');
    } else {
      firstName = developerAccount.firstName;
      lastName = developerAccount.lastName;
    }
    await createTestAccount({
      firstName,
      lastName,
      locationCount
    })
      .then(({ data: { createTestOrganization: testAccount } }) => {
        trackEvent('developerPortal.testAccountPageCreateButton.clicked', {
          testAccountUid: testAccount.uid
        });
        setIsCreating(false);
        cancelOnClick();

        if (refetchTestAccounts != null) refetchTestAccounts();
        if (onCreate) onCreate();

        toast({
          status: 'success',
          title: 'Test account created. Check your e-mail to get started!'
        });
      })
      .catch(() => {
        setIsCreating(false);
        cancelOnClick();
        toast({
          status: 'error',
          title: 'Error creating test account.'
        });
      });
  };

  const handleOnContinueClick = () => {
    if (name.length > 255) {
      setNameError('Name is too long.');
    } else if (name.trim().split(' ').length === 1) {
      setNameError('First and last name are required.');
    } else {
      setIsNextStep(true);
    }
  };

  let body1;
  let body2;

  if (isNextStep || hasAccountName) {
    body1 = 'How many locations do you want in this Test account?';
    body2 = (
      <Text>
        Locations are hypothetical physical locations that your business resides in. Learn more
        about{' '}
        <Link
          color={COLORS.blue.dark}
          href="https://help.podium.com/hc/en-us/articles/360039414194-Navigating-the-Inbox#Change%20your%20Inbox%20location"
          isExternal
        >
          Locations
        </Link>
        .
      </Text>
    );
  } else {
    body1 = 'We take security seriously.';
    body2 = 'Enter your name so we know you are you.';
  }

  return (
    <Modal contentLabel="Create Test Account Modal" onClose={cancelOnClick} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a Test Account</ModalHeader>
        <ModalCloseButton onClick={cancelOnClick} />
        <ModalBody>
          {(isNextStep || hasAccountName) && (
            <Text paddingBottom="10px">
              <i>Hi {name || `${developerAccount.firstName} ${developerAccount.lastName}`}!</i>{' '}
              <br /> Test accounts are free Podium accounts that include trials of many paid
              features.{' '}
              <Link
                color={COLORS.blue.dark}
                href="https://docs.podium.com/docs/podium-test-accounts"
                isExternal
              >
                Learn more
              </Link>
            </Text>
          )}
          <Text fontWeight="semibold" paddingBottom="5px">
            {body1}
          </Text>
          <Text paddingBottom="20px">{body2}</Text>
          <FormControl isInvalid={nameError}>
            {isNextStep || hasAccountName ? (
              <Select placeholder="Select" onChange={(e) => setLocationCount(e.target.value)}>
                <option value="1">1 location</option>
                <option value="2">2 locations</option>
                <option value="3">3 locations</option>
              </Select>
            ) : (
              <>
                <Input
                  placeholder="Firstname Lastname"
                  onChange={(event) => {
                    setNameError(null);
                    setName(event.target.value);
                  }}
                />
                <FormErrorMessage>{nameError}</FormErrorMessage>
              </>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm" spacing={2}>
            <Button type="text" variant="secondary" onClick={cancelOnClick}>
              Cancel
            </Button>
            {isNextStep || hasAccountName ? (
              <Button
                loadingText="Creating..."
                isLoading={isCreating}
                variant="primary"
                onClick={onCreateTestAccountClick}
                disabled={!locationCount || isCreating}
              >
                Create
              </Button>
            ) : (
              <Button
                disabled={isEmptyString(name)}
                variant="primary"
                onClick={handleOnContinueClick}
              >
                Continue
              </Button>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CreateTestAccountModal.propTypes = {
  developerAccount: PropTypes.shape({ firstName: PropTypes.string, lastName: PropTypes.string }),
  isOpen: PropTypes.bool.isRequired,
  onCreate: PropTypes.func,
  refetchTestAccounts: PropTypes.func,
  setIsClosed: PropTypes.func.isRequired
};
