import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@podiumhq/design-system';
import styled from '@emotion/styled';

export default function Error({ buttonOnClick, buttonText, header, icon, iconAlt, message }) {
  return (
    <ErrorContainer>
      <Img src={icon} alt={iconAlt} />
      <h1>{header}</h1>
      <ErrorMessage>{message}</ErrorMessage>
      {buttonOnClick && buttonText && (
        <Button onClick={buttonOnClick} variant="primary">
          {buttonText}
        </Button>
      )}
    </ErrorContainer>
  );
}

Error.propTypes = {
  buttonOnClick: PropTypes.func,
  buttonText: PropTypes.string,
  header: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  iconAlt: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

Error.defaultProps = {
  buttonOnClick: null,
  buttonText: null
};

const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ErrorMessage = styled.p`
  padding-bottom: 30px;
`;

const Img = styled.img`
  width: 168px;
  height: auto;
`;
