import { gql, useMutation } from '@apollo/client';

export const MUTATION = gql`
  mutation sendTestWebhookEvent($uid: UUID!) {
    sendTestWebhookEvent(uid: $uid) {
      status
    }
  }
`;

export const useSendTestWebhookMutation = () => {
  const [mutate] = useMutation(MUTATION);
  return (variables) => mutate({ variables });
};
