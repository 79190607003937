import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTracking } from '@podiumhq/tracking';

import ConfigOauthPure from './ConfigOauthPure';
import {
  useApiScopeListQuery,
  useCreatePresignedLogoUrl,
  useDeveloperAccountQuery,
  useRegenerateOauthClientSecretMutation,
  useTestAccountListQuery,
  useUpsertApplicationOauthClientMutation
} from '../../../graphql';

export default function ConfigOauth(props) {
  const { oauthClient, uid: developerApplicationUid } = props;
  const { apiScopeList } = useApiScopeListQuery();
  const { developerAccount } = useDeveloperAccountQuery();
  const { testAccountList } = useTestAccountListQuery();
  const { trackEvent } = useTracking();

  const { upsertDeveloperApplicationOauthClient, clientSecret: clientSecretFromMutation } =
    useUpsertApplicationOauthClientMutation();

  const [clientSecret, setClientSecret] = useState(clientSecretFromMutation);
  const [logoFileError, setLogoFileError] = useState('');

  const regenerateClientSecret = useRegenerateOauthClientSecretMutation();

  const createPresignedUrl = useCreatePresignedLogoUrl();

  const uploadLogoFile = (file) => {
    const [, extension] = file.type.split('/');
    return createPresignedUrl({ developerApplicationUid, extension })
      .then((response) => {
        const {
          data: {
            createPresignedLogoUrl: { presignedUrl, url }
          }
        } = response;

        const options = {
          method: 'PUT',
          headers: {
            'Content-Type': file.type
          },
          body: file
        };

        return fetch(presignedUrl, options).then(() => url);
      })
      .catch((e) => {
        e.graphQLErrors.forEach((error) => {
          if (
            error.message === 'The html extension is not supported. Please try a gif, jpg, or png'
          ) {
            setLogoFileError(error.message);
          }
        });
      });
  };

  // Does not allow clientSecret to be unset once it is set
  useEffect(() => {
    setClientSecret((prevClientSecret) => {
      if (!prevClientSecret && clientSecretFromMutation) return clientSecretFromMutation;
      return prevClientSecret;
    });
  }, [clientSecretFromMutation]);

  return (
    <ConfigOauthPure
      {...props}
      apiScopeList={apiScopeList}
      createPresignedLogoUrl={createPresignedUrl}
      developerAccount={developerAccount}
      logoFileError={logoFileError}
      oauthClient={{ ...oauthClient, clientSecret }}
      onSubmit={upsertDeveloperApplicationOauthClient}
      regenerateClientSecret={() => {
        trackEvent('developerPortal.oAuthPageRegenerateSecretButton.clicked', {
          applicationUid: developerApplicationUid
        });
        regenerateClientSecret({ developerApplicationUid }).then((response) => {
          const {
            data: { regenerateOauthClientSecret: newClientSecret }
          } = response;

          setClientSecret(newClientSecret);
        });
      }}
      testAccountList={testAccountList}
      uploadLogoFile={uploadLogoFile}
    />
  );
}

ConfigOauth.propTypes = {
  oauthClient: PropTypes.shape({
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    logoUrl: PropTypes.string,
    redirectUrl: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  uid: PropTypes.string.isRequired
};
