import { gql, useMutation } from '@apollo/client';

export const MUTATION = gql`
  mutation sendWelcomeEmail($testOrganizationId: UUID!) {
    sendWelcomeEmail(testOrganizationId: $testOrganizationId) {
      uid
    }
  }
`;

export const useSendWelcomeEmailMutation = () => {
  const [mutate] = useMutation(MUTATION);
  return (variables) => mutate({ variables });
};
