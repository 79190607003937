import { gql, useMutation } from '@apollo/client';

export const MUTATION = gql`
  mutation regenerateOauthClientSecret($developerApplicationUid: UUID!) {
    regenerateOauthClientSecret(developerApplicationUid: $developerApplicationUid)
  }
`;

export const useRegenerateOauthClientSecretMutation = () => {
  const [mutate] = useMutation(MUTATION);
  return (variables) => mutate({ variables });
};
