/* eslint-disable */
import { PENDO_API_KEY } from '../../../lib/env';
import isQABot from '../../../lib/qa-bot-utils/isQABot';

export const pendoScript = () =>
  (function (apiKey) {
    if (process.env.NODE_ENV !== 'production' || isQABot()) return;
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(PENDO_API_KEY);

const loadPendo = (pendo, user) => {
  if (pendo) {
    pendo.identify({
      visitor: {
        id: user.email,
        email: user.email
      },
      account: {
        id: user.email
      }
    });
  }
};

export const addPendo = (pendo, user) => {
  if (pendo) {
    pendo.initialize();
  }

  if (user) {
    loadPendo(pendo, user);
  }

  return null;
};
