import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation softDeleteTestOrganization($uid: UUID!) {
    softDeleteTestOrganization(uid: $uid) {
      uid
    }
  }
`;

export const useSoftDeleteTestOrganizationMutation = () => {
  const [mutate] = useMutation(MUTATION);
  return (variables) => mutate({ variables });
};
