import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import styled from '@emotion/styled';
import {
  Button,
  COLORS,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip
} from '@podiumhq/design-system';
import { useTracking } from '@podiumhq/tracking';

import Config from '../Config';
import DebounceButton from '../../../components/DebounceButton';
import { isEmptyString, trimFormValues } from '../../../lib/string-utils';
import ResourceLinksTable from '../ResourceLinksTable';
import SmartFormInput from '../../../components/SmartFormInput';
import { PUBLIC_DOCUMENTATION_HOST } from '../../../lib/env';
import rocketTwo from '../../../images/Rocket2.png';
import CreateTestAccountModal from '../../../components/CreateTestAccountModal';

const isSaveDisabled = (formValues, originalValues, newLogoFile) =>
  (isEqual(formValues, originalValues) && !newLogoFile) || isEmptyString(formValues.redirectUrl);

function ScopeMenuItem({ text, isChecked, callback }) {
  return (
    <MenuItem
      key={text}
      closeOnSelect={false}
      margin="0"
      onClick={(e) => {
        // clicking checkbox inside a menu item was triggering the onClick twice
        // temporary hack needed while we wait for the official design system multi select
        if (e.target.className !== 'chakra-checkbox__input') {
          callback();
        }
      }}
      width="100%"
    >
      <Checkbox isChecked={isChecked} marginRight="8px" />
      {text}
    </MenuItem>
  );
}
export default function ConfigOauthPure({
  apiScopeList,
  developerAccount,
  logoFileError,
  oauthClient: { clientId, clientSecret, logoUrl, redirectUrl, scopes },
  onSubmit,
  regenerateClientSecret,
  testAccountList,
  uid,
  uploadLogoFile
}) {
  const sortedScopeList = sortBy(apiScopeList, [(scope) => scope.name.split(' ')[1]]);
  const inputRef = useRef();
  const { trackEvent } = useTracking();

  const [originalValues, setOriginalValues] = useState({
    developerApplicationUid: uid,
    logoUrl: logoUrl || null,
    redirectUrl: redirectUrl || '',
    scopes: scopes || []
  });

  const [formValues, setFormValues] = useState(originalValues);
  const [redirectUrlError, setRedirectUrlError] = useState('');
  const [newLogoFile, setNewLogoFile] = useState(null);
  const [isCreateTestAccountModalOpen, setIsCreateTestAccountModalOpen] = useState(false);

  const history = useHistory();

  const toggleScopeSelect = (slug) => {
    const i = formValues.scopes.indexOf(slug);
    const newSelectedScopes = [...formValues.scopes];

    if (i === -1) {
      newSelectedScopes.push(slug);
    } else {
      newSelectedScopes.splice(i, 1);
    }

    trackEvent('developerPortal.oAuthPageSelectScopesSelect.changed');
    setFormValues({ ...formValues, scopes: newSelectedScopes });
  };

  const getSelectedScopesText = () => {
    switch (formValues.scopes.length) {
      case 0:
        return 'Select Scopes';
      case apiScopeList.length:
        return 'All Scopes';
      case 1:
        return find(apiScopeList, ['slug', formValues.scopes[0]])?.name;
      default:
        return `${formValues.scopes.length} scopes selected`;
    }
  };

  const appLogoImage = () => {
    if (newLogoFile) {
      return <AppLogoImage alt="app-icon" src={newLogoFile.fileUrl} />;
    }
    if (logoUrl) {
      return <AppLogoImage alt="app-icon" src={logoUrl} />;
    }

    return <Icon type="Plus" boxSize="30px" color="silver.base" data-testid="icon-plus" />;
  };

  const onFileChange = ({
    target: {
      files: [file]
    }
  }) => {
    if (newLogoFile) {
      URL.revokeObjectURL(newLogoFile.fileUrl);
    }
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setNewLogoFile({ fileUrl, file });
    }
  };

  const onSave = async () => {
    trackEvent('developerPortal.oAuthPageSaveButton.clicked');
    const dirtyFormValues = { ...formValues };
    if (newLogoFile) {
      dirtyFormValues.logoUrl = await uploadLogoFile(newLogoFile.file);
    }

    const cleanedFormValues = trimFormValues({ ...dirtyFormValues });

    return onSubmit(cleanedFormValues)
      .then(() => {
        setFormValues(cleanedFormValues);
        setOriginalValues(cleanedFormValues);
      })
      .catch((e) => {
        e.graphQLErrors.forEach((error) => {
          if (error.key === 'oauth_client.redirect_url' && error.message === 'must use HTTPS') {
            setRedirectUrlError('Redirect URL must use HTTPS.');
          } else if (
            error.key === 'oauth_client.redirect_url' &&
            error.message === 'cannot contain a fragment'
          ) {
            setRedirectUrlError('Redirect URL cannot contain a fragment.');
          }
        });
        return Promise.reject();
      });
  };

  const form = (
    <FormWrapper>
      <FormContainer>
        <FormLabel htmlFor="upload-icon">App Icon</FormLabel>
        <AppLogo
          logoAvailable={newLogoFile?.fileUrl || logoUrl}
          onClick={() => inputRef.current.click()}
        >
          <ImageInput
            id="upload-icon"
            data-testid="image-input"
            type="file"
            onChange={onFileChange}
            ref={inputRef}
          />
          {appLogoImage()}
        </AppLogo>
        <ImageErrorMessage>{logoFileError}</ImageErrorMessage>
      </FormContainer>
      <InputWrapper>
        <FormLabel htmlFor="redirect-url">Redirect URL</FormLabel>
        <FormControl isInvalid={redirectUrlError}>
          <Input
            id="redirect-url"
            onChange={(e) => {
              setRedirectUrlError(false);
              setFormValues({ ...formValues, redirectUrl: e.target.value });
            }}
            value={formValues.redirectUrl}
          />
          <FormErrorMessage>{redirectUrlError}</FormErrorMessage>
        </FormControl>
      </InputWrapper>

      <FormLabel htmlFor="scopes-select">Scopes</FormLabel>

      <ScopesWrapper>
        <Menu matchWidth id="scopes-select" aria-label="Scopes Select" closeOnSelect={false}>
          <MenuButton data-testid="scopes-select" width="200px">
            {getSelectedScopesText()}
          </MenuButton>
          <MenuList height="240px" type="checkbox">
            {sortedScopeList.map(({ name, slug }) => (
              <ScopeMenuItem
                key={name}
                text={name}
                isChecked={formValues.scopes.includes(slug)}
                callback={() => toggleScopeSelect(slug)}
              />
            ))}
          </MenuList>
        </Menu>
        <ScopesTooltip
          label="Apps should only request the minimum scopes needed to function."
          placement="right"
        >
          <Icon
            type="Info"
            boxSize="25px"
            color="black.base"
            data-testid="scopes-tool-tip"
            marginLeft="5px"
            alignSelf="center"
          />
        </ScopesTooltip>
      </ScopesWrapper>
    </FormWrapper>
  );

  const resourceLinks = [
    { text: 'OAuth 2.0 Overview', url: `${PUBLIC_DOCUMENTATION_HOST}/docs/oauth` },
    { text: 'OAuth Scopes', url: `${PUBLIC_DOCUMENTATION_HOST}/docs/oauth-scopes` }
  ];

  const resources = (
    <div>
      <ResourceText>
        OAuth 2.0 provides developers with a secure way to access the Podium API on behalf of other
        Podium users.
      </ResourceText>
      <ResourceLinksTable resources={resourceLinks} />
    </div>
  );

  const additionalContent = clientId ? (
    <CredentialsWrapper>
      <InputWrapper>
        <SmartFormInput
          id="client-id"
          label="Client ID"
          placeholder="This value will be generated on save"
          value={clientId || ''}
          disabled
          isCopyable
        />
      </InputWrapper>
      <InputWrapper>
        {clientSecret && (
          <div>
            <SmartFormInput
              className="fs-exclude"
              id="client-secret"
              label="Client Secret"
              placeholder="This value will be generated on save"
              helpText="Make sure you save it - you won't be able to see it again!"
              value={clientSecret || ''}
              disabled
              isCopyable
            />
          </div>
        )}
      </InputWrapper>
      <RegenerateClientSecretWrapper>
        <DebounceButton onClick={regenerateClientSecret}>Regenerate Client Secret</DebounceButton>
        <RegenerateClientSecretInfo>
          <Icon
            type="AlertTriangleSolid"
            boxSize="30px"
            color="gold.base"
            style={{ marginRight: '5px' }}
          />
          Does not revoke existing OAuth tokens.
        </RegenerateClientSecretInfo>
      </RegenerateClientSecretWrapper>
    </CredentialsWrapper>
  ) : null;

  const createTestAccountOnClick = () => {
    setIsCreateTestAccountModalOpen(true);
    trackEvent('developerPortal.oAuthPageCreateTestAccountButton.modalOpened');
  };

  const testAccountContent =
    testAccountList && testAccountList.length === 0 ? (
      <TestAccountWrapper>
        <Empty>
          <Image margin="30px 30px 0 30px" boxSize="120px" src={rocketTwo} />
          <BoxText>
            <Heading fontSize="xl" paddingBottom="1%" fontWeight="semibold">
              Ready to test things out?
            </Heading>
            <Text paddingBottom="1%" fontSize="lg">
              Test accounts allow you to kick the tires without affecting production data or
              incurring costs.
            </Text>
            <Text fontSize="sm" paddingRight="40%">
              Create a test account to explore our APIs and features, test your application, and
              preview functionality before publishing. Learn more about{' '}
              <Link
                color={COLORS.blue.dark}
                href="https://docs.podium.com/docs/podium-test-accounts"
                isExternal
              >
                Test Accounts
              </Link>
              .
            </Text>
            <StyledButton variant="primary" size="sm" onClick={createTestAccountOnClick}>
              Create Test Account
            </StyledButton>
          </BoxText>
        </Empty>
        <CreateTestAccountModal
          developerAccount={developerAccount}
          onCreate={() => history.push('/dashboard')}
          isOpen={isCreateTestAccountModalOpen}
          setIsClosed={() => {
            setIsCreateTestAccountModalOpen(false);
          }}
        />
      </TestAccountWrapper>
    ) : null;

  return (
    <Config
      additionalContent={additionalContent}
      additionalContentTitle="Credentials"
      form={form}
      onSave={onSave}
      promptContent={testAccountContent}
      resources={resources}
      saveDisabled={isSaveDisabled(formValues, originalValues, newLogoFile)}
      title="OAuth"
    />
  );
}

ConfigOauthPure.propTypes = {
  apiScopeList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  ),
  clientSecret: PropTypes.string,
  developerAccount: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  logoFileError: PropTypes.string,
  oauthClient: PropTypes.shape({
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    logoUrl: PropTypes.string,
    redirectUrl: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  regenerateClientSecret: PropTypes.func.isRequired,
  testAccountList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uid: PropTypes.string
    })
  ),
  uid: PropTypes.string.isRequired,
  uploadLogoFile: PropTypes.func.isRequired
};

ConfigOauthPure.defaultProps = {
  apiScopeList: [],
  clientSecret: '',
  logoFileError: null
};

ScopeMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired
};

const AppLogo = styled.div((props) => ({
  alignItems: 'center',
  border: !props.logoAvailable && `2px dashed ${COLORS.silver.base}`,
  color: COLORS.silver.base,
  display: 'flex',
  height: '74px',
  justifyContent: 'center',
  width: '74px',
  cursor: 'pointer'
}));

const AppLogoImage = styled.img`
  height: 74px;
  width: 74px;
`;

const BoxText = styled.div`
  padding-top: 30px;
`;

const CredentialsWrapper = styled.div`
  max-width: 750px;
`;

const Empty = styled.div`
  border-radius: 10px;
  display: flex;
  width: '85%';
`;

const FormContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const FormWrapper = styled.div`
  max-width: 500px;
`;

const ImageInput = styled.input`
  display: none;
`;

const InputWrapper = styled.div`
  padding-bottom: 10px;
`;

const ImageErrorMessage = styled.div`
  color: ${COLORS.red.light};
  font-size: 12px;
`;

const RegenerateClientSecretInfo = styled.span`
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

const RegenerateClientSecretWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResourceText = styled.p`
  padding-bottom: 10px;
`;

const ScopesTooltip = styled(Tooltip)`
  display: flex;
  align-self: center;
  padding-left: 5px;
`;

const ScopesWrapper = styled(InputWrapper)`
  display: flex;
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.black.base};
  margin: 2% 0 2% 0;
`;

const TestAccountWrapper = styled.div`
  background-color: ${COLORS.blue.lightest};
`;
