import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { IconLightBulb } from '@podiumhq/icons';
import { Button, COLORS, useToast } from '@podiumhq/design-system';
import AreYouSureModal from '../../components/AreYouSureModal';

export default function Config({
  additionalContent,
  additionalContentTitle,
  form,
  formStyle,
  hideControls,
  onCancel,
  onSave,
  promptContent,
  resources,
  saveDisabled,
  subtitle,
  title
}) {
  const history = useHistory();
  const [loadedState, setLoadedState] = useState(false);
  const [modalState, setModalState] = useState(false);
  const toast = useToast();
  const shouldShowModal = modalState === true && saveDisabled === false;

  return (
    <Wrapper data-testid="ApplicationConfig">
      <ActionBar>
        <div>
          <Title>{title}</Title>
          <h3>{subtitle}</h3>
        </div>
        {!hideControls && (
          <div>
            <CancelButton
              type="text"
              onClick={() => {
                if (saveDisabled === false) {
                  setModalState(true);
                } else {
                  setModalState(false);
                  if (onCancel) onCancel();
                  else history.push('/dashboard');
                }
              }}
            >
              Cancel
            </CancelButton>
            <Button
              variant="primary"
              disabled={saveDisabled}
              isLoading={loadedState}
              loadingText="Saving..."
              onClick={async () => {
                setLoadedState(true);
                await onSave()
                  .then(() => {
                    toast({
                      status: 'success',
                      title: 'Application Saved!'
                    });
                    setLoadedState(false);
                  })
                  .catch(() => {
                    setLoadedState(false);
                  });
              }}
            >
              Save
            </Button>
          </div>
        )}
      </ActionBar>
      <Content>
        <FormWrapper resources={!!resources}>
          <FormInner style={formStyle}>{form}</FormInner>
        </FormWrapper>
        {resources && (
          <Resources>
            <ResourcesTitle>
              <IconLightBulb />
              Resources
            </ResourcesTitle>
            {resources}
          </Resources>
        )}
      </Content>
      {additionalContent && (
        <div>
          <Title>{additionalContentTitle}</Title>
          <AdditionalContent>{additionalContent}</AdditionalContent>
        </div>
      )}
      {promptContent && (
        <div>
          <Prompt>{promptContent}</Prompt>
        </div>
      )}

      <AreYouSureModal
        heading="Are you sure you want to leave?"
        isOpen={shouldShowModal}
        noButtonText="Stay on Page"
        onNo={() => setModalState(false)}
        onYes={() => history.push('/dashboard')}
        subheading="You will lose any unsaved changes made on this page."
        yesButtonText="Leave this Page"
      />
    </Wrapper>
  );
}

Config.propTypes = {
  additionalContent: PropTypes.element,
  additionalContentTitle: PropTypes.string,
  form: PropTypes.element.isRequired,
  formStyle: PropTypes.shape(),
  hideControls: PropTypes.bool,
  resources: PropTypes.element,
  onCancel: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool,
  subtitle: PropTypes.string,
  promptContent: PropTypes.element,
  title: PropTypes.string.isRequired
};

Config.defaultProps = {
  additionalContent: null,
  additionalContentTitle: null,
  formStyle: {},
  hideControls: false,
  resources: null,
  saveDisabled: true
};

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const AdditionalContent = styled.div`
  display: flex;
  border: 1px solid ${COLORS.silver.base};
  border-radius: 6px;
  margin-top: 20px;
  padding: 25px;
  flex-direction: column;
`;

const CancelButton = styled(Button)`
  margin-right: 10px;
`;

const Content = styled.div`
  display: flex;
  border: 1px solid ${COLORS.silver.base};
  border-radius: 6px;
  margin-bottom: 20px;
`;

const FormWrapper = styled.div`
  ${(props) => (props.resources ? `border-right: 1px solid ${COLORS.silver.base};` : ``)}

  min-height: 100px;
  min-width: 650px;
  max-width: 800px;
  width: 75%;
`;

const FormInner = styled.div`
  min-height: 100px;
  min-width: 500px;
  padding: 25px;
`;

const Resources = styled.div`
  padding: 25px;
`;

const ResourcesTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  display: flex;
  padding-bottom: 10px;
`;

const Prompt = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 30px;
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 75px;
  margin-right: 150px;
  flex-grow: 1;
  max-width: 1200px;
`;
