import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Button } from '@podiumhq/design-system';

const INTERVAL = 1000;

export default function DebounceButton({ onClick, children }) {
  // eslint-disable-next-line
  const debouncedOnClick = useCallback(
    debounce(onClick, INTERVAL, { leading: true, trailing: false, maxWait: INTERVAL }),
    []
  );

  return (
    <Button variant="primary" onClick={debouncedOnClick}>
      {children}
    </Button>
  );
}

DebounceButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired
};

DebounceButton.defaultProps = {
  children: null
};
