import { gql, useQuery } from '@apollo/client';

export const QUERY = gql`
  query apiVersionList {
    apiVersionList
  }
`;

export const useApiVersionListQuery = () => {
  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const apiVersionList = data?.apiVersionList;

  return { apiVersionList, loading };
};
