const addPodiumWidget = (podiumToken) => {
  const existingScript = document.getElementById('podium-widget');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://connect.podium.com/widget.js#API_TOKEN=${podiumToken}`;
    script.id = 'podium-widget';
    script.setAttribute('data-api-token', podiumToken);
    document.body.appendChild(script);
  }
};

export default addPodiumWidget;
