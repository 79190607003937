import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import inRange from 'lodash/inRange';
import { Button, COLORS, FormLabel, Switch, useToast } from '@podiumhq/design-system';
import { useTracking } from '@podiumhq/tracking';
import { datadogRum } from '@datadog/browser-rum';

import AlertBox from '../../../components/AlertBox';
import AreYouSureModal from '../../../components/AreYouSureModal';
import { isEmptyString, trimFormValues } from '../../../lib/string-utils';
import Config from '../Config';
import SmartFormInput from '../../../components/SmartFormInput';

const DISABLED_BANNER_TEXT = 'max_retry';
const INPUT_WIDTH = '600px';

const isSaveDisabled = (formValues, originalValues) =>
  isEqual(formValues, originalValues) || isEmptyString(formValues.url);

export default function ConfigWebhookPure({
  deleteWebhook,
  closeWebhook,
  onSubmit,
  sendTestWebhook,
  uid,
  webhook: {
    disabledAt,
    disabledReason,
    enabledEventTypes,
    locationUid,
    secret,
    url,
    uid: webhookUid
  }
}) {
  const [originalValues] = useState({
    developerApplicationUid: uid,
    disabled: !!disabledAt,
    enabledEventTypes: enabledEventTypes || [],
    secret: secret || '',
    url: url || '',
    uid: webhookUid
  });
  const toast = useToast();
  const [formValues, setFormValues] = useState(originalValues);
  const [webhookTestStatus, setWebhookTestStatus] = useState(null);
  const [webhookUrlError, setWebhookUrlError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useTracking();

  const displayTestResult = () => {
    let bgColor = COLORS.red.lighter;
    let color = COLORS.red.darker;
    let icon = 'XCircle';
    let message =
      webhookTestStatus === 660
        ? 'Unable to send test event'
        : `Status code returned: ${webhookTestStatus}`;

    if (inRange(webhookTestStatus, 200, 300)) {
      bgColor = COLORS.green.lighter;
      color = COLORS.green.darker;
      icon = 'CheckCircle';
      message = 'Successful!';
    }

    return (
      <AlertBox data-testid="status-response" bgColor={bgColor} color={color} icon={icon}>
        {message}
      </AlertBox>
    );
  };

  const onSave = () => {
    const cleanedFormValues = trimFormValues(formValues);
    return onSubmit(cleanedFormValues)
      .then(() => {
        trackEvent('developerPortal.webhooksPageSaveWebhookButton.clicked');
        closeWebhook();
      })
      .catch((e) => {
        e.graphQLErrors.forEach((error) => {
          if (error.key === 'webhook.url' && error.message === 'must use HTTPS') {
            setWebhookUrlError('Endpoint URL must use HTTPS.');
          } else if (error.key === 'webhook.url' && error.message === 'cannot contain a fragment') {
            setWebhookUrlError('Endpoint URL cannot contain a fragment.');
          } else if (error.key === 'webhook.url' && error.message === 'bad host') {
            setWebhookUrlError('Endpoint URL is inaccessible.');
          }
        });
        return Promise.reject();
      });
  };

  const sendTestOnClick = () => {
    trackEvent('developerPortal.webhooksPageSendTestEventButton.clicked');
    sendTestWebhook({
      uid: webhookUid
    }).then((data) => {
      setWebhookTestStatus(data.data.sendTestWebhookEvent.status);
    });
  };

  const form = (
    <div>
      <InputWrapper>
        <SmartFormInput
          id="webhook-url-input"
          label="Endpoint URL"
          value={formValues.url}
          onChange={(e) => {
            setWebhookUrlError('');
            setFormValues({ ...formValues, url: e.target.value });
          }}
          isCopyable
          isInvalid={webhookUrlError}
          width={INPUT_WIDTH}
          errorMessage={webhookUrlError}
        />
      </InputWrapper>
      <InputWrapper>
        <SmartFormInput
          className="fs-exclude"
          isCopyable
          isSecret
          label="Secret"
          onChange={(e) => setFormValues({ ...formValues, secret: e.target.value })}
          value={formValues.secret}
          style={{ fontSize: '15px' }}
          width={INPUT_WIDTH}
        />
      </InputWrapper>
      <InputWrapper>
        <FormLabel htmlFor="enabled-events">Subscribed Events</FormLabel>
        <span id="enabled-events">
          {formValues.enabledEventTypes.map((event) => (
            <Event key={event}>{event}</Event>
          ))}
        </span>
      </InputWrapper>
      <InputWrapper>
        <FormLabel htmlFor="webhook-location-uid">Location</FormLabel>
        <span data-test-id="webhook-location-uid" id="webhook-location-uid">
          {locationUid ? `UID: ${locationUid} ` : 'Global, All Locations'}
        </span>
      </InputWrapper>
      {originalValues.url !== '' ? (
        <InputWrapper>
          <FormLabel htmlFor="webhook-disabled">Webhook Status</FormLabel>
          <Switch
            id="webhook-disabled"
            size="lg"
            data-testid="webhook-disabled"
            isChecked={!formValues.disabled}
            onChange={(e) => {
              trackEvent('developerPortal.webhooksPageChangeStatusButton.changed');
              setFormValues({ ...formValues, disabled: !e.target.checked });
            }}
          >
            Enabled
          </Switch>
        </InputWrapper>
      ) : null}
      <InputWrapper>
        <FormLabel htmlFor="send-test-webhook-event">Send Test Webhook Event</FormLabel>
        <Button
          variant="primary"
          id="send-test-webhook-event"
          data-testid="send-test-webhook-event"
          onClick={sendTestOnClick}
        >
          Send Test
        </Button>
        {webhookTestStatus ? displayTestResult() : null}
      </InputWrapper>
      <InputWrapper>
        <FormLabel htmlFor="delete-webhook-button">Delete Webhook</FormLabel>
        <Button
          variant="primary"
          colorScheme="destructive"
          id="delete-webhook-button"
          data-testid="delete-webhook-button"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete Webhook
        </Button>
      </InputWrapper>
    </div>
  );

  return (
    <div>
      {disabledReason === DISABLED_BANNER_TEXT && (
        <div data-testid="WebhookAlertBox">
          <WebhookDisabledAlertBox
            bgColor="rgba(231, 62, 81, 0.15)"
            color="rgba(231, 62, 81)"
            icon="AlertTriangle"
          >
            Your webhook was disabled on {disabledAt} after multiple failed attempts to respond with
            a 200 OK status. Please make sure your endpoint is available and working properly before
            re-enabling the webhook.
          </WebhookDisabledAlertBox>
        </div>
      )}
      <Config
        form={form}
        onCancel={closeWebhook}
        onSave={onSave}
        saveDisabled={isSaveDisabled(formValues, originalValues)}
        title="Webhook"
        subtitle={`UID: ${webhookUid}`}
      />
      <AreYouSureModal
        isDisabled={isLoading}
        heading={!isLoading ? 'Are you sure you want to delete this webhook?' : 'Deleting...'}
        isOpen={showDeleteModal}
        noButtonText="Cancel"
        onNo={() => setShowDeleteModal(false)}
        onYes={async () => {
          trackEvent('developerPortal.webhooksPageDeleteWebhookButton.clicked');
          setIsLoading(true);
          deleteWebhook({
            uid: webhookUid,
            developerApplicationUid: originalValues.developerApplicationUid
          })
            .then(() => {
              closeWebhook();
              toast({
                status: 'success',
                title: 'Webhook Deleted!'
              });
            })
            .catch((e) => {
              setShowDeleteModal(false);
              setIsLoading(false);
              toast({
                status: 'error',
                title: 'Webhook Deletion Failed!'
              });
              datadogRum.addError(e);
            });
        }}
        subheading="This action cannot be undone."
        yesButtonText="Delete"
      />
    </div>
  );
}

ConfigWebhookPure.propTypes = {
  deleteWebhook: PropTypes.func.isRequired,
  closeWebhook: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  sendTestWebhook: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  webhook: PropTypes.shape({
    disabledAt: PropTypes.string,
    disabledReason: PropTypes.string,
    enabledEventTypes: PropTypes.arrayOf(PropTypes.string),
    locationUid: PropTypes.string,
    secret: PropTypes.string,
    url: PropTypes.string,
    uid: PropTypes.string
  }).isRequired
};

const Event = styled.span`
  margin: 2px;
  padding: 4px 5px;
  background-color: ${COLORS.green.light};
  color: white;
  font-size: 12px;
  border-radius: 3px;
`;

const InputWrapper = styled.div`
  padding-bottom: 10px;
`;

const WebhookDisabledAlertBox = styled(AlertBox)`
  margin-left: 75px;
  margin-right: 150px;
  width: 1176px;
`;
