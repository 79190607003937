import { gql, useQuery } from '@apollo/client';

export const QUERY = gql`
  query developerApplicationsList {
    developerApplicationsList {
      uid
      name
    }
  }
`;

export const useApplicationListQuery = ({ skip = false }) => {
  const { data, loading, refetch } = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network',
    skip
  });
  const applicationList = data?.developerApplicationsList || [];

  return { applicationList, loading, refetchApplications: refetch };
};
