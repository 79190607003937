import React from 'react';
import PropTypes from 'prop-types';

import ConfigWebhooksPure from './ConfigWebhooksPure';
import ConfigWebhook from '../ConfigWebhook';

export default function ConfigWebhooks({ selectedWebhook, selectWebhook, ...props }) {
  return selectedWebhook ? (
    <ConfigWebhook {...props} webhook={selectedWebhook} closeWebhook={() => selectWebhook(null)} />
  ) : (
    <ConfigWebhooksPure selectWebhook={selectWebhook} {...props} />
  );
}

ConfigWebhooks.propTypes = {
  selectedWebhook: PropTypes.shape({
    enabledEventTypes: PropTypes.arrayOf(PropTypes.string),
    secret: PropTypes.string,
    url: PropTypes.string,
    uid: PropTypes.string
  }),
  selectWebhook: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  webhooks: PropTypes.arrayOf(
    PropTypes.shape({
      enabledEventTypes: PropTypes.arrayOf(PropTypes.string),
      secret: PropTypes.string,
      url: PropTypes.string,
      uid: PropTypes.string
    })
  ).isRequired
};
