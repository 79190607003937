import React from 'react';
import styled from '@emotion/styled';
import propTypes from 'prop-types';
import { Icon } from '@podiumhq/design-system';

export default function AlertBox({
  bgColor = 'rgba(255, 198, 54, 0.2)',
  color = '#7c7353',
  icon = 'Info',
  children
}) {
  return (
    <Wrapper bgColor={bgColor} color={color} role="alert">
      <OutsideDiv>
        <IconDiv>
          <Icon type={icon} boxSize="35px" />
        </IconDiv>
        <TextDiv>{children}</TextDiv>
      </OutsideDiv>
    </Wrapper>
  );
}

AlertBox.propTypes = {
  bgColor: propTypes.string,
  color: propTypes.string,
  children: propTypes.node.isRequired,
  icon: propTypes.string
};

const IconDiv = styled.div`
  float: left;
`;

const OutsideDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextDiv = styled.div`
  text-align: left;
  padding-left: 20px;
`;

const Wrapper = styled.div`
  margin: 20px 150px 0 75px;
  ${(props) => `background: ${props.bgColor}`};
  border-radius: 10px;
  ${(props) => `color: ${props.color}`};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  width: fit-content;
`;
