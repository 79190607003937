import React from 'react';
import PropTypes from 'prop-types';

const buildRow = (text, url, idx) => (
  <tr key={idx}>
    <td>
      <a href={url} rel="noreferrer" target="_blank">
        <div>
          <span>{text}</span>
        </div>
      </a>
    </td>
  </tr>
);

export default function ResourceLinksTable({ resources }) {
  return (
    <table>
      <tbody>{resources.map((link, idx) => buildRow(link.text, link.url, idx))}</tbody>
    </table>
  );
}

ResourceLinksTable.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  )
};

ResourceLinksTable.defaultProps = {
  resources: []
};
