import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody
} from '@podiumhq/design-system';

import { CLICKWRAP_ACCESS_ID, CLICKWRAP_GROUP_KEY } from '../../lib/env';
import { getLatestContracts, sendAcceptance } from './clickwrap';

export default function TermsofServiceModal({ userId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [listenerAdded, setListenerAdded] = useState(false);

  const onCheckboxChange = () => {
    let agreedToAll = true;
    const checkboxList = document.getElementsByClassName('ps-checkbox');
    for (let i = 0; i < checkboxList.length; i += 1) {
      if (!checkboxList[i].checked) {
        agreedToAll = false;
      }
    }

    if (agreedToAll) {
      setAgreed(true);
    } else {
      setAgreed(false);
    }
  };

  useEffect(() => {
    if (userId) {
      getLatestContracts(userId).then((contracts) => {
        if (Object.values(contracts).includes(false)) {
          setIsOpen(true);
        }
      });
    }
  }, [userId]);

  const onAgree = () => {
    const contractIds = [];
    const checkboxList = document.getElementsByClassName('ps-checkbox');
    for (let i = 0; i < checkboxList.length; i += 1) {
      contractIds.push(checkboxList[i].value.split(':')[1]);
    }

    sendAcceptance(userId, contractIds.join()).then(() => setIsOpen(false));
  };

  const onValid = () => {
    if (!listenerAdded) {
      const checkboxList = document.getElementsByClassName('ps-checkbox');
      for (let i = 0; i < checkboxList.length; i += 1) {
        checkboxList[i].addEventListener('change', onCheckboxChange);
        setListenerAdded(true);
      }
    }

    setAgreed(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Updated Terms of Use</ModalHeader>
        <ModalBody>
          <BodyText>
            We've updated our Terms of Use. Please take a minute to review changes that might be
            important to you. Please accept our updated terms to continue using Podium Developer
            Portal.
          </BodyText>
          <PSClickWrap
            accessId={CLICKWRAP_ACCESS_ID}
            disableSending
            groupKey={CLICKWRAP_GROUP_KEY}
            onValid={onValid}
            signerId={userId}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm" spacing={4}>
            <Button variant="primary" disabled={!agreed} onClick={onAgree}>
              Agree
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

TermsofServiceModal.propTypes = {
  userId: PropTypes.string
};

const BodyText = styled.div`
  margin-bottom: 30px;
`;
