import { gql, useQuery } from '@apollo/client';
import { allApplicationFields } from './util';

export const QUERY = gql`
  query developerApplication($uid: UUID!) {
    developerApplication(uid: $uid) {
      ${allApplicationFields}
    }
  }
`;

export const useApplicationQuery = (uid) => {
  const variables = { uid };
  const { data, loading } = useQuery(QUERY, {
    variables,
    fetchPolicy: 'network-only',
    skip: !uid
  });
  const application = data?.developerApplication || {};

  return { application, loading };
};
