import { ApolloProvider, ApolloClient, from, InMemoryCache, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DEVELOPER_PORTAL_GATEWAY_HOST } from '../env';

function AuthorizedApolloProvider({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const httpLink = new HttpLink({ uri: `${DEVELOPER_PORTAL_GATEWAY_HOST}/graphql` });
  const retryLink = new RetryLink();
  const errorLink = onError((e) => {
    if (!e.graphQLErrors) {
      history.push('/server-unavailable');
    }
  });

  const authLink = setContext(async (_, { headers, ...rest }) => {
    const token = await getAccessTokenSilently();

    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink, retryLink, httpLink]),
    cache: new InMemoryCache()
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthorizedApolloProvider;
