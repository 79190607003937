import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  COLORS,
  useToast
} from '@podiumhq/design-system';

export default function SmartFormInput({
  errorMessage,
  helpText,
  isCopyable,
  isSecret,
  width,
  label,
  ...props
}) {
  const [show, setShow] = useState(false);
  const toast = useToast();

  return (
    <div>
      <FormLabel htmlFor={label}>{label}</FormLabel>
      <Wrapper>
        <InputGroup size="md" width={width}>
          <Input {...props} id={label} type={isSecret && !show ? 'password' : 'text'} />

          {isSecret && (
            <InputRightElement>
              <IconButton
                data-testid="secretIcon"
                fillColor="black"
                onClick={() => setShow(!show)}
                type={show ? 'EyeCrossed' : 'Eye'}
                variant="ghost"
              />
            </InputRightElement>
          )}
        </InputGroup>

        {isCopyable && (
          <CopyButton
            data-testid="copyButton"
            variant="ghost"
            type="Duplicate"
            size="md"
            onClick={() => {
              navigator.clipboard.writeText(props.value);
              toast({
                status: 'success',
                title: 'Copied Successfully!'
              });
            }}
          />
        )}
      </Wrapper>
      {errorMessage && <SubText isError>{errorMessage}</SubText>}
      {!errorMessage && helpText && <SubText>{helpText}</SubText>}
    </div>
  );
}

SmartFormInput.propTypes = {
  errorMessage: PropTypes.string,
  helpText: PropTypes.string,
  isCopyable: PropTypes.bool,
  isSecret: PropTypes.bool,
  value: PropTypes.string.isRequired,
  width: PropTypes.string,
  label: PropTypes.string
};

SmartFormInput.defaultProps = {
  errorMessage: null,
  helpText: null,
  isCopyable: false,
  isSecret: false,
  width: null
};

const CopyButton = styled(IconButton)`
  margin-left: 10px;
  height: 40px;
`;

const SubText = styled.div`
  font-size: 14px;
  margin-top: 8px;
  color: ${(props) => (props.isError ? COLORS.red.base : COLORS.grey.base)};
  a {
    color: inherit;
    font-weight: 600;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
