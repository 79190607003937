import { QUERY as APPLICATION_QUERY } from './useApplicationQuery';

export const allApplicationFields = `
  uid
  name
  oauthClient {
    clientId
    clientSecret
    logoUrl
    redirectUrl
    scopes
  }
  webhooks {
    disabledAt
    disabledReason
    enabledEventTypes
    locationUid
    organizationUid
    secret
    url
    uid
  }
`;

export const updateApplicationCache = (client, application) => {
  if (application) {
    client.writeQuery({
      query: APPLICATION_QUERY,
      variables: { uid: application.uid },
      data: { developerApplication: application }
    });
  }
};

export const updateApplicationWebhooksCache = (client, webhook, developerApplicationUid) => {
  const data = client.readQuery({
    query: APPLICATION_QUERY,
    variables: { uid: developerApplicationUid }
  });

  const index = data?.developerApplication.webhooks.findIndex(({ uid }) => uid === webhook.uid);
  const webhooks = index && data?.developerApplication.webhooks.splice(index, 1, webhook);

  client.writeQuery({
    query: APPLICATION_QUERY,
    variables: { uid: developerApplicationUid },
    data: { developerApplication: { webhooks } }
  });
};

export const deleteWebhookInCache = (client, webhook) => {
  const data = client.readQuery({
    query: APPLICATION_QUERY,
    variables: { uid: webhook.developerApplicationUid }
  });

  const webhooks = data?.developerApplication.webhooks.filter((w) => w.uid !== webhook.uid);

  client.writeQuery({
    query: APPLICATION_QUERY,
    variables: { uid: webhook.developerApplicationUid },
    data: { developerApplication: { webhooks } }
  });
};
