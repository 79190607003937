import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { updateApplicationWebhooksCache } from './util';

export const MUTATION = gql`
  mutation updateWebhook(
    $developerApplicationUid: UUID!
    $disabled: Boolean
    $secret: String
    $url: String!
    $uid: UUID!
  ) {
    updateWebhook(
      developerApplicationUid: $developerApplicationUid
      disabled: $disabled
      secret: $secret
      url: $url
      uid: $uid
    ) {
      disabledAt
      disabledReason
      enabledEventTypes
      locationUid
      organizationUid
      secret
      url
      uid
    }
  }
`;

export const useUpdateApplicationWebhookMutation = () => {
  const [mutate] = useMutation(MUTATION);
  return (variables) =>
    mutate({
      variables,
      update: (client, result) => {
        updateApplicationWebhooksCache(
          client,
          result?.data?.updateWebhook,
          variables.developerApplicationUid
        );
      }
    });
};
