import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation createDeveloperApplication($name: String!) {
    createDeveloperApplication(name: $name) {
      uid
    }
  }
`;

export const useCreateApplicationMutation = () => {
  const [mutate] = useMutation(MUTATION, {});
  return (variables) => mutate({ variables });
};
