import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import Application from '../Application';
import Dashboard from '../Dashboard';
import Error from '../Error';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import LandingPage from '../LandingPage';
import NotFoundError from '../../images/NotFoundError.svg';
import UnexpectedError from '../../images/UnexpectedError.svg';
import TopNav from '../../components/TopNav';

function ProtectedRoute({ component, onRedirecting, ...args }) {
  return <Route component={withAuthenticationRequired(component, { onRedirecting })} {...args} />;
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  onRedirecting: PropTypes.func
};

ProtectedRoute.defaultProps = {
  onRedirecting: () => null
};

export default function Root() {
  return (
    <>
      <TopNav />
      <ErrorBoundary>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <ProtectedRoute
            path="/dashboard"
            exact
            component={() => <Dashboard />}
            onRedirecting={() => <Dashboard authLoading />}
          />
          <ProtectedRoute
            path="/application/:applicationUid"
            exact
            component={() => <Application />}
          />
          <ProtectedRoute
            path="/server-unavailable"
            component={() => (
              <Error
                header="Service temporarily unavailable"
                icon={UnexpectedError}
                iconAlt="Server Unavailable Error"
                message="The server is not responding. Please try again later."
              />
            )}
          />
          <ProtectedRoute
            component={() => (
              <Error
                header="Page not found"
                icon={NotFoundError}
                iconAlt="Not Found Error"
                message="The page you are looking for does not exist."
              />
            )}
          />
        </Switch>
      </ErrorBoundary>
    </>
  );
}
